<template>
  <div v-if="shouldDisplayComment" class="mb-32 flex flex-col">
    <span class="body-1-bold mb-8">
      {{ i18n(translations.sellerComment) }}
    </span>
    <span class="body-2">
      {{ comment }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './SellerComment.translations'

const props = defineProps<{
  comment: string
}>()

const i18n = useI18n()
const shouldDisplayComment = computed(() => !!props.comment)
</script>
