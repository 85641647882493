<template>
  <RevStickyBar class="block md:hidden" position="top" :visible="showBar">
    <div class="py-4">
      {{ title }}
    </div>
    <slot name="progress-bar" />
  </RevStickyBar>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { RevStickyBar } from '@ds/components/StickyBar'

defineProps<{
  title: string
}>()

const emit = defineEmits<{
  (event: 'show'): void
  (event: 'hide'): void
}>()

const showBar = ref(false)
const lastYPosition = ref(0)

function handleScroll() {
  if (window.matchMedia('(max-width: 767px)').matches) {
    window.requestAnimationFrame(() => {
      // To have the correct behavior we need to target the first component of the bottom of the page.
      // Reco are not mandatory so if the reco block is failing, we don't have our limit anymore.
      // Fallback would be reviews but we can have products without reviews.
      // Fallback would be the eco block but we have it only for smartphones.
      // Fallback would be SEO tech specs has it's on every page.
      const recoSection = document.querySelector('[data-id="reco-section"]')
      const reviewsSection = document.querySelector('#reviews')
      const ecoBlockSection = document.querySelector('[data-id="eco-block"]')
      const seoTechSpecSection = document.querySelector(
        '[data-id="seo-technical-specs"]',
      )
      const bottomPageSection =
        recoSection || reviewsSection || ecoBlockSection || seoTechSpecSection
      const bottomPageSectionTopScroll =
        bottomPageSection?.getBoundingClientRect().top

      // Show the Sticky bar when ATC is no more visible
      // Show the Sticky bar when scrolling down
      if (
        window.scrollY > 300 &&
        (lastYPosition.value < window.scrollY ||
          (bottomPageSectionTopScroll && bottomPageSectionTopScroll > 0))
      ) {
        showBar.value = true
        emit('show')
      } else if (lastYPosition.value > window.scrollY) {
        showBar.value = false
        emit('hide')
      }
      lastYPosition.value = window.scrollY
    })
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
