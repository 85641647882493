<template>
  <div>
    <div class="flex flex-row justify-between">
      <p class="text-static-default-hi body-1 mb-8 block">
        {{ pickerTitle }}
      </p>
      <slot name="modal" />
    </div>
    <ul class="grid list-none gap-8" :class="gridColumnsClass">
      <li
        v-for="(item, index) in itemsToDisplay"
        :key="item.label"
        :data-qa="`${pickerId}-${index}`"
      >
        <Picker
          :available="item.available"
          :color="item.color"
          :good-deal="item.goodDeal"
          :group-id="picker.id"
          :has-staff-pick="!hasGoodDeal"
          :icon="item.icon"
          :index="index"
          :label="item.label"
          :link="item.link"
          :loading="item.loading"
          :price="item.price"
          :selected="item.selected"
          :should-display-sold-out="shouldDisplaySoldOut"
          :tracking-id="picker.trackingId"
          :tracking-value="item.trackingValue"
          @click="handleClick(index)"
        />
      </li>

      <li v-if="isItemsLengthHigherThanMax">
        <LargePicker
          :aria-expanded="displayAllItems"
          class="m-auto h-full"
          :index="itemsToDisplay.length"
          :label="togglerLabel"
          @click="toggleDisplayAllItems"
        />
      </li>
    </ul>

    <slot :name="`extra-${picker.id}`" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import LargePicker from '../../../CustomizationFunnel/components/LargePicker/LargePicker.vue'
import type { PickersWithLoading } from '../../Pickers.types'
import Picker from '../Picker/Picker.vue'

import {
  COLS_CLASS_DEFAULT,
  COLS_CLASS_OVERRIDES,
  DISPLAY_SOLD_OUT,
  MAX_PICKER_ITEMS,
} from './PickerGroup.constants'
import translations from './PickerGroup.translations'

const i18n = useI18n()

const props = defineProps<{
  picker: PickersWithLoading['pickers'][number]
}>()

const emit = defineEmits(['change'])

const displayAllItems = ref(false)

const isItemsLengthHigherThanMax = computed(() => {
  return props.picker.items.length > MAX_PICKER_ITEMS
})

const maximumAmountOfItemsToShow = computed(() => {
  return MAX_PICKER_ITEMS - 1
})

const remainingItemsCount = computed(() => {
  return props.picker.items.length - maximumAmountOfItemsToShow.value
})

const itemsToDisplay = computed(() => {
  const { items } = props.picker

  if (isItemsLengthHigherThanMax.value && !displayAllItems.value) {
    return items.slice(0, maximumAmountOfItemsToShow.value)
  }

  return items
})

const togglerLabel = computed(() => {
  const { showMore, showLess } = translations

  return displayAllItems.value
    ? i18n(showLess)
    : i18n(showMore, { remaining: remainingItemsCount.value })
})

const pickerTitle = computed(() => {
  return props.picker.label
})

const pickerId = computed(() => {
  return props.picker.id
})

const gridColumnsClass = computed(() => {
  return COLS_CLASS_OVERRIDES[pickerId?.value] ?? COLS_CLASS_DEFAULT
})

const shouldDisplaySoldOut = computed(() => {
  return DISPLAY_SOLD_OUT.includes(pickerId.value)
})

const hasGoodDeal = computed(() => {
  return (
    props.picker.id === 'grades' &&
    !!props.picker.items.find((item) => item.goodDeal)
  )
})

function toggleDisplayAllItems() {
  displayAllItems.value = !displayAllItems.value
}
function handleClick(index: number) {
  emit('change', props.picker.id, index)
}
</script>
