<template>
  <LargePicker
    :aria-disabled="disabled"
    :color="color"
    :description="description"
    :disabled="disabled"
    :index="index"
    :is-good-deal="hasGoodDealIcon"
    :label="label"
    :loading="loading"
    :prefix="pickerPrefix"
    :price="description"
    :selected="selected"
    :tag="tag"
    :to="to"
    @click="onClick"
    @click-prevent="onClickPrevent"
  />
</template>

<script lang="ts" setup>
import { useRouter } from '#imports'
import { computed } from 'vue'

import { type LinkInternal, type Price } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { hashToQueryLink } from '../../../../utils/hashToQueryLink'
import LargePicker from '../../../CustomizationFunnel/components/LargePicker/LargePicker.vue'

import translations from './Picker.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
const experiments = useExperiments()
const router = useRouter()

export type PickerProps = {
  shouldDisplaySoldOut?: boolean
  label: string
  trackingId?: string | null
  trackingValue?: string | null
  link?: LinkInternal | undefined
  available: boolean
  selected: boolean
  icon?: string
  index: number
  price?: Price | null
  color?: string
  goodDeal?: boolean
  loading?: boolean
  groupId: string
  hasStaffPick?: boolean
}

const props = withDefaults(defineProps<PickerProps>(), {
  shouldDisplaySoldOut: false,
  trackingId: '',
  trackingValue: '',
  icon: '',
  link: undefined,
  price: null,
  color: undefined,
  goodDeal: false,
  hasStaffPick: false,
  loading: false,
})

const emit = defineEmits(['click'])

const disabled = computed(() => !props.available)

const to = computed(() => {
  if (!props.available || !props.link) {
    return undefined
  }

  return {
    ...props.link,
    hash: {
      ...props.link.hash,
      // Adding a scroll=false param to avoid transitioning when using pickers. See product.vue
      scroll: 'false',
    },
  }
})

const description = computed(() => {
  if (props.shouldDisplaySoldOut && disabled.value) {
    return i18n(translations.soldOut)
  }

  if (!isEmpty(props.price) && props.trackingId === 'grade_picker') {
    return i18n.price(props.price)
  }

  return ''
})

function onClickPrevent() {
  if (props.available && props.link) {
    const link = hashToQueryLink({
      ...props.link,
      hash: {
        ...props.link.hash,
        // Adding a scroll=false param to avoid transitioning when using pickers. See product.vue
        scroll: 'false',
      },
    }) as unknown as LinkInternal
    router.push(toNuxtLink(link))
  }
}

const tag = computed(() => {
  if (
    props.goodDeal &&
    props.trackingId === 'grade_picker' &&
    !['withGoodDealIcon', 'withGoodDealIconAndStaffPickLabel'].includes(
      experiments['experiment.ppGoodDealStaffPick'],
    )
  ) {
    return i18n(translations.goodDeal)
  }

  if (
    props.hasStaffPick &&
    props.trackingId === 'grade_picker' &&
    props.trackingValue === '11' &&
    props.available &&
    experiments['experiment.ppGoodDealStaffPick'] ===
      'withGoodDealIconAndStaffPickLabel'
  ) {
    return i18n(translations.bestValue)
  }

  return ''
})

const pickerPrefix = computed(() => {
  // Empty color means that we are missing the color hash but it is still a color picker
  if (props.color || props.color === '') {
    return 'color'
  }

  return undefined
})

const hasGoodDealIcon = computed(() => {
  return (
    props.goodDeal &&
    ['withGoodDealIcon', 'withGoodDealIconAndStaffPickLabel'].includes(
      experiments['experiment.ppGoodDealStaffPick'],
    )
  )
})

function onClick() {
  if (disabled.value || props.selected) {
    return
  }

  // We're a bit defensive on those trackingId / trackingValue, as the API specs says that
  // both fields are optional. Let's have default values to make sure we're able to detect this
  // in amplitude no matter what
  trackClick({
    zone: props.trackingId ?? 'unknown_picker',
    name: props.trackingValue ?? props.label,
  })

  emit('click')
}
</script>
