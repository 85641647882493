import type { Component } from 'vue'

import { type Grade } from '@backmarket/http-api'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { IconDiamond } from '@ds/icons/IconDiamond'
import { IconOfficialFilled } from '@ds/icons/IconOfficialFilled'
import { IconScratches } from '@ds/icons/IconScratches'
import { IconSparkles } from '@ds/icons/IconSparkles'

import translations from './ConditionGuidance.translations'

type GradeGallery = Array<{
  src: string
  alt?: I18nDefinition
  title?: I18nDefinition
  pills?: Array<{
    text: I18nDefinition
    icon: Component
  }>
}>

export const GRADE_TO_GALLERY: Partial<Record<Grade['value'], GradeGallery>> = {
  9: [
    {
      src: '/img/product/funnel/smartphone_premium_9_1',
      title: translations.premium1_title,
      pills: [
        {
          text: translations.premium1_pill1,
          icon: IconDiamond,
        },
        {
          text: translations.premium1_pill2,
          icon: IconDiamond,
        },
        {
          text: translations.premium1_pill3,
          icon: IconOfficialFilled,
        },
      ],
    },
    {
      src: '/img/product/funnel/smartphone_premium_9_2',
      title: translations.premium2_title,
      pills: [
        {
          text: translations.premium2_pill,
          icon: IconDiamond,
        },
      ],
    },
  ],
  10: [
    {
      src: '/img/product/funnel/smartphone_premium_10_1',
      title: translations.excellent1_title,
      pills: [
        {
          text: translations.excellent1_pill,
          icon: IconSparkles,
        },
      ],
    },
    {
      src: '/img/product/funnel/smartphone_premium_10_2',
      title: translations.excellent2_title,
      pills: [
        {
          text: translations.excellent2_pill,
          icon: IconSparkles,
        },
      ],
    },
  ],
  11: [
    {
      src: '/img/product/funnel/smartphone_premium_11_1',
      title: translations.good1_title,
      pills: [
        {
          text: translations.good1_pill,
          icon: IconScratches,
        },
      ],
    },
    {
      src: '/img/product/funnel/smartphone_premium_11_2',
      title: translations.good2_title,
      pills: [
        {
          text: translations.good2_pill,
          icon: IconSparkles,
        },
      ],
    },
  ],
  12: [
    {
      src: '/img/product/funnel/smartphone_premium_12_1',
      title: translations.fair1_title,
      pills: [
        {
          text: translations.fair1_pill,
          icon: IconScratches,
        },
      ],
    },
    {
      src: '/img/product/funnel/smartphone_premium_12_2',
      title: translations.fair2_title,
      pills: [
        {
          text: translations.fair2_pill,
          icon: IconScratches,
        },
      ],
    },
  ],
}
