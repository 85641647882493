export default {
  title: {
    id: 'product_page_premium_battery_modal_title',
    defaultMessage: 'Battery options',
  },
  section1Title: {
    id: 'product_page_premium_battery_modal_section1_title',
    defaultMessage: 'What battery do you need?',
  },
  section1Description: {
    id: 'product_page_premium_battery_modal_section1_description',
    defaultMessage:
      'Our Quality Charter guarantees you’ll get a great battery in your phone regardless. But, if you’re a super user and need long charges for daily use, you can boost a selection of smartphones with an extra charge battery.',
  },
  section1Note: {
    id: 'product_page_premium_battery_modal_note',
    defaultMessage:
      '*Battery capacity is the amount of energy a battery can hold, also considered the leading health indicator for a battery.',
  },
  section1Link: {
    id: 'product_page_premium_battery_modal_link',
    defaultMessage: 'Learn more about Premium',
  },
  section2Title: {
    id: 'product_page_premium_battery_modal_section2_title',
    defaultMessage: 'Frequently Asked Questions',
  },

  section2Question1: {
    id: 'product_page_premium_battery_modal_section2_question1',
    defaultMessage: 'Do Premium batteries come from {brand}?',
  },
  section2Answer1Paragraph1: {
    id: 'product_page_premium_battery_modal_section2_answer1_paragraph1',
    defaultMessage:
      'Short answer — yes.  They come from the original manufacturer and are tested fully functional by {brand}. If there’s ever anything that feels off with your battery-boosted smartphone, {link, html} can help.',
  },
  section2Answer1Paragraph1LinkText: {
    id: 'product_page_premium_battery_modal_section2_answer1_paragraph1_link_text',
    defaultMessage: 'Back Market Customer Care',
  },
  section2Answer1Paragraph1Link: {
    id: 'product_page_premium_battery_modal_section2_answer1_paragraph1_link',
    defaultMessage: 'https://www.backmarket.com/en-us/help',
  },
  section2Question2: {
    id: 'product_page_premium_battery_modal_section2_question2',
    defaultMessage:
      'Why is the extra charge battery price different with different devices?',
  },
  section2Answer2Paragraph1: {
    id: 'product_page_premium_battery_modal_section2_answer2_paragraph1',
    defaultMessage:
      'The price of the Premium battery fluctuates because the associated cost differs across devices in our marketplace.',
  },
  section2Answer2Paragraph2: {
    id: 'product_page_premium_battery_modal_section2_answer2_paragraph2',
    defaultMessage:
      'For example, if a Back Market seller purchased a device that already had a extra charge battery, the associated cost would be less than a device they had to purchase a battery spare part for.',
  },
  section2Answer2Paragraph3: {
    id: 'product_page_premium_battery_modal_section2_answer2_paragraph3',
    defaultMessage:
      'Sounds like a conundrum? We make it easier. Back Market handpicks the best mix of price and quality among many different options to help you decide.',
  },
  section2Question3: {
    id: 'product_page_premium_battery_modal_section2_question3',
    defaultMessage: 'What are Back Market’s battery standards?',
  },
  section2Answer3Paragraph1: {
    id: 'product_page_premium_battery_modal_section2_answer3_paragraph1',
    defaultMessage:
      "Not all refurbished phones come with an extra charge battery because they don't all need one. Your refurbished phone is guaranteed to have a battery that was rigorously checked and tested to be great for daily use. Every device sold on Back Market is required to have a minimum of 80% capacity — which is different from charge percentage, it'll still charge to 100%. {link, html}. If your device doesn't reach at least 80% of it's initial charge capacity when you receive it, reach out to Back Market Customer Care and you'll get a replacement. Back Market enforces a guarantee from all sellers that smartphones sold on our marketplace have a minimum of 80% capacity.",
  },
  section2Answer3Paragraph1LinkText: {
    id: 'product_page_premium_battery_modal_section2_answer3_paragraph1_link_text',
    defaultMessage: 'You can learn more about what this means here',
  },
  section2Answer3Paragraph1Link: {
    id: 'product_page_premium_battery_modal_section2_answer3_paragraph1_link',
    defaultMessage:
      'https://help.backmarket.com/hc/en-us/articles/360026656374-How-long-can-I-expect-my-battery-to-last',
  },
}
