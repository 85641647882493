import { productAPI } from '@backmarket/http-api'

export function hasVisibleByVerizon(
  partnerPromoCodes: productAPI.GetProductResponse['includedServiceOffers']['partnerPromoCodes'],
) {
  return (
    partnerPromoCodes?.some(
      (partnerPromoCode) => partnerPromoCode.partnerName === 'VISIBLE',
    ) ?? false
  )
}
