import type { Router } from 'vue-router'

import {
  type Locale,
  MarketCountryCode,
  productAPI,
} from '@backmarket/http-api'
import { getAllMarkets } from '@backmarket/nuxt-module-marketplace/getAllMarkets'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

const typesMap: Record<
  productAPI.GetProductResponseV2['type'],
  productAPI.GetProductResponse['type']
> = {
  Refurbished: 'refurbished',
  'Brand new': 'accessory',
  Digital: 'accessory',
}

const carriersLogoMap: Record<string, string> = {
  Verizon: 'verizon.svg',
  'AT&T': 'att.svg',
  'T-Mobile': 'tmobile.svg',
}

export const transformProductResponse = (
  product: productAPI.GetProductResponseV2,
  countryCode: MarketCountryCode,
  locale: Locale,
  router: Router,
): productAPI.GetProductResponse => ({
  id: product.productId,
  legacyId: product.productId,
  model: product.model,
  priceWhenNew: product.priceWhenNew,
  isSwapEligible: product.isTradeInEligible,
  subTitleElements: product.subtitleElements,
  images: product.images.map(({ url, description, source = 'CATALOG' }) => ({
    alt: description,
    url,
    source,
  })),
  trackingDetails: {
    brand: product.brand,
    categories: [],
    category3MarketplaceCategory_ID: parseInt(product.tracking.categoryId, 10),
    category3Name: product.tracking.categoryName,
    color: product.tracking.color,
  },
  tags: [],
  insurancesOffers: {},
  eWaste: '',
  description: product.seo.description,
  showVerifiedRefurbished: product.showVerifiedRefurbished,
  link: {
    type: 'internal',
    name: 'product',
    pattern: '/:locale/p/:slugV2/:uuid',
    params: {
      slugV2: product.seo.slug,
      uuid: product.productId,
      locale: toBcp47Locale(locale),
    },
    query: {},
    hash: {},
    href: `https://www${getAllMarkets()[countryCode]?.domain}${
      router.resolve({
        name: 'product',
        params: {
          slugV2: product.seo.slug,
          uuid: product.productId,
          locale: toBcp47Locale(locale),
        },
      }).href
    }`,
  },
  links: product.seo.marketsLinks
    .filter(({ slug }) => !!slug)
    .reduce(
      (
        acc,
        cur: productAPI.GetProductResponseV2['seo']['marketsLinks'][number],
      ) => ({
        ...acc,
        [cur.countryCode]: {
          type: 'internal',
          name: 'product',
          pattern: '/:locale/p/:slugV2/:uuid',
          params: {
            slugV2: cur.slug,
            uuid: product.productId,
            locale: toBcp47Locale(
              getAllMarkets()[cur.countryCode]?.defaultLocale ?? locale,
            ),
          },
          href: `https://www${getAllMarkets()[cur.countryCode]?.domain}${
            router.resolve({
              name: 'product',
              params: {
                slugV2: cur.slug,
                uuid: product.productId,
                locale: toBcp47Locale(
                  getAllMarkets()[cur.countryCode]?.defaultLocale ?? locale,
                ),
              },
            }).href
          }`,
        },
      }),
      {},
    ),
  type: typesMap[product.type],
  title: product.titles.default,
  rawTitle: product.titles.raw,
  compatibleCarriers: product.carrier.isDisplayed
    ? [
        {
          name: product.carrier.name ?? '',
          isSimLocked: product.carrier.locked,
          logo: product.carrier.name
            ? `/img/product/carriers/${carriersLogoMap[product.carrier.name]}`
            : '',
        },
      ]
    : [],
  includedServiceOffers: product.includedServiceOffers,
})
