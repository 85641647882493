<template>
  <div>
    <div class="flex items-baseline gap-4">
      <h2 class="heading-2" data-qa="productpage-product-price">
        {{ i18n.price(price) }}
      </h2>
      <PriceLabel />
    </div>

    <div class="flex flex-wrap items-center gap-x-8">
      <OriginalPrice
        :is-discount-reassurance-displayed="
          offer.reassurance?.discount?.isDisplayed
        "
        :price-when-new="product.priceWhenNew"
      />

      <YouSave
        :category-id="product.trackingDetails.category3MarketplaceCategory_ID"
        :is-discount-reassurance-displayed="
          offer.reassurance?.discount?.isDisplayed
        "
        :price="offer.price"
        :price-when-new="product.priceWhenNew"
      />
    </div>

    <VatIncluded class="mt-4" />
  </div>
</template>

<script lang="ts" setup>
import { type Price, productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import OriginalPrice from './components/OriginalPrice/OriginalPrice.vue'
import PriceLabel from './components/PriceLabel/PriceLabel.vue'
import VatIncluded from './components/VatIncluded/VatIncluded.vue'
import YouSave from './components/YouSave/YouSave.vue'

defineProps<{
  offer: productAPI.GetBestOffersResponse[number]
  price: Price
  product: productAPI.GetProductResponse
}>()

const i18n = useI18n()
</script>
