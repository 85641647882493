export default {
  title: {
    id: 'product_tech_specs_modal_title',
    defaultMessage: 'Technical Specifications',
  },
  search: {
    id: 'product_tech_specs_modal_search',
    defaultMessage: 'Filter specifications',
  },
}
