export default {
  section1Item1Title: {
    id: 'product_page_premium_battery_modal_section1_item1_title',
    defaultMessage: 'Standard',
  },
  section1Item1Paragraph1: {
    id: 'product_page_premium_battery_modal_section1_item1_paragraph1',
    defaultMessage: 'At least 80% battery capacity*',
  },
  section1Item1Paragraph2: {
    id: 'product_page_premium_battery_modal_section1_item1_paragraph2',
    defaultMessage: 'Expert-approved spare battery',
  },
  section1Item1Paragraph3: {
    id: 'product_page_premium_battery_modal_section1_item1_paragraph3',
    defaultMessage: 'Great functionality for daily use',
  },
  section1Item2Title: {
    id: 'product_page_premium_battery_modal_section1_item2_title',
    defaultMessage: 'Premium',
  },
  section1Item2Paragraph1: {
    id: 'product_page_premium_battery_modal_section1_item2_paragraph1',
    defaultMessage: 'At least 90% battery capacity*',
  },
  section1Item2Paragraph2: {
    id: 'product_page_premium_battery_modal_section1_item2_paragraph2',
    defaultMessage: 'Official {brand} battery',
  },
  section1Item2Paragraph3: {
    id: 'product_page_premium_battery_modal_section1_item2_paragraph3',
    defaultMessage: '1.5 hr more streaming time daily, subject to use',
  },
}
