export default {
  titleUnlocked: {
    id: 'product_carriers_compatibility_modal_title_unlocked',
    defaultMessage: 'What are unlocked phones?',
  },
  titleLocked: {
    id: 'product_carriers_compatibility_modal_title_locked',
    defaultMessage: 'What are locked phones?',
  },
  titleUnlockedFull: {
    id: 'product_carriers_compatibility_modal_main_title_unlocked',
    defaultMessage: 'Unlocked phones: works with all carriers',
  },
  titleLockedFull: {
    id: 'product_carriers_compatibility_modal_main_title_locked',
    defaultMessage: 'Locked phones: connected to a specific carrier',
  },
  unlockedP1: {
    id: 'product_carriers_compatibility_modal_paragraph_1',
    defaultMessage:
      'If you have an unlocked phone, you can connect to any carrier in the US and be able to switch carriers in the future.',
  },
  unlockedP2Wrapper: {
    id: 'product_carriers_compatibility_modal_paragraph_2_wrapper',
    defaultMessage:
      'Not sure if the phone you’re looking at on Back Market is unlocked? Check the device title or {link, html}',
  },
  unlockedP2Link: {
    id: 'product_carriers_compatibility_modal_paragraph_2_link',
    defaultMessage: 'view all our unlocked phones here',
  },
  lockedTitle: {
    id: 'product_carriers_compatibility_modal_locked_title',
    defaultMessage: 'What is a locked phone?',
  },
  lockedP1: {
    id: 'product_carriers_compatibility_modal_locked_paragraph_1',
    defaultMessage:
      'Locked phones are connected to a specific carrier. A software-level lock on the device, often called firmware, prevents the user from using the phone with another carrier.',
  },
  lockedP2Wrapper: {
    id: 'product_carriers_compatibility_modal_locked_paragraph_2_wrapper',
    defaultMessage:
      'Not sure if the phone you’re looking at on Back Market is locked? {link, html}',
  },
  lockedP2Link: {
    id: 'product_carriers_compatibility_modal_locked_paragraph_2_link',
    defaultMessage:
      'You can look up phones by carrier by using the side navigation',
  },

  guideCategory: {
    id: 'product_carriers_compatibility_modal_guide_category',
    defaultMessage: 'Events',
  },
  guideTitle: {
    id: 'product_carriers_compatibility_modal_guide_title',
    defaultMessage: 'How to activate my phone',
  },
  guideText: {
    id: 'product_carriers_compatibility_modal_guide_text',
    defaultMessage:
      'You’ll be on your phone in no time. Unlocked or locked, this guide will help you set up your new-to-you phone and connect with your carrier so you can start using it.',
  },
  guideMore: {
    id: 'product_carriers_compatibility_modal_guide_more',
    defaultMessage: 'See more',
  },
}
