export default {
  title: {
    id: 'product_add_to_cart_modal_title_pluralized',
    defaultMessage: `{count, plural,
      one {{product} added to cart}
      other {# items were added to cart}
    }`,
  },
  question: {
    id: 'product_add_to_cart_modal_question',
    defaultMessage: 'What would you like to do next?',
  },
  goToCart: {
    id: 'product_add_to_cart_modal_go_to_cart',
    defaultMessage: 'Go to cart',
  },
  continue: {
    id: 'product_add_to_cart_modal_continue',
    defaultMessage: 'Continue shopping',
  },
  close: {
    id: 'product_add_to_cart_modal_close',
    defaultMessage: 'Close',
  },
  crossSell: {
    id: 'recommendation_cross_sell_title',
    defaultMessage: 'Plays nice with',
  },
  crossSellAccessories: {
    id: 'recommendation_cross_sell_accessories_title',
    defaultMessage: 'Make it last (even longer)',
  },
}
