import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  tradeInProposalName: {
    id: 'product_page_customization_result_trade_in_name',
    defaultMessage: 'Your trade-in:',
  },
  tradeInProposalPrice: {
    id: 'product_page_customization_result_trade_in_price',
    defaultMessage: 'cash back',
  },
  tradeInProposalDescription: {
    id: 'product_page_customization_result_trade_in_description',
    defaultMessage:
      'You’ll get paid {price} once we receive your device and confirm it matches the assessment.',
  },
} as Record<string, I18nDefinition>
