export default {
  beforeTradeIn: {
    id: 'product_price_wrapper_trade_in',
    defaultMessage: 'before trade-in',
  },
  withMobilePlan: {
    id: 'product_price_label_with_mobile_plan',
    defaultMessage: 'with mobile plan',
  },
}
