export default {
  label: {
    id: 'product_page_new_battery_label',
    defaultMessage: '{grade} - with new battery',
  },
  link: {
    id: 'product_page_new_battery_link',
    defaultMessage: 'See if you need it',
  },
  disabled: {
    id: 'product_page_new_battery_disabled',
    defaultMessage: '{grade} - with new battery - Sold out ',
  },
  disabledV2: {
    id: 'product_page_new_battery_disabled_v2',
    defaultMessage: '{grade} - with new battery - coming soon',
  },
  trigger: {
    id: 'product_page_new_battery_modal_trigger',
    defaultMessage: 'See if you need it',
  },
  compare: {
    id: 'product_page_new_battery_compare',
    defaultMessage: 'Compare battery options',
  },
  education: {
    id: 'product_page_new_battery_education',
    defaultMessage:
      'Learn about your guaranteed healthy battery and other options',
  },
}
