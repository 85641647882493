<template>
  <div v-if="shouldDisplayPickers">
    <PickerGroup
      v-for="group in pickersWithLoading"
      :key="group.id"
      class="mb-32"
      :picker="group"
      @change="handleChange"
    >
      <template #modal v-if="hasModal(group.id)">
        <component :is="getModal(group.id)" />
      </template>
      <template #extra-grades>
        <NewBattery
          :grade="currentGradeLabel"
          :offer-type="newBattery?.offerType"
          :product-category="trackingDetails.category3MarketplaceCategory_ID"
          :status="newBattery?.status"
          @change="handleNewBatteryChange"
        />
      </template>
    </PickerGroup>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { productAPI } from '@backmarket/http-api'

import { PICKERS_MODALS } from './Pickers.constants'
import NewBattery from './components/NewBattery/NewBattery.vue'
import PickerGroup from './components/PickerGroup/PickerGroup.vue'

const props = defineProps<{
  newBattery?: productAPI.GetBestOffersResponse[number]['newBattery']
  pickers: productAPI.GetPickersResponse['pickers']
  trackingDetails: productAPI.GetProductResponse['trackingDetails']
}>()

const emit = defineEmits(['change'])
const loadingGroup = ref('')
const loadingIndex = ref<number | undefined>(undefined)

watch(props, () => {
  loadingGroup.value = ''
  loadingIndex.value = undefined
})

const shouldDisplayPickers = computed(() => {
  return props.pickers.length > 0
})

const pickersWithoutBattery = computed(() => {
  return props.pickers.filter(({ id }) => id !== 'battery')
})

const pickersWithLoading = computed(() => {
  return pickersWithoutBattery.value.map((picker) => ({
    ...picker,
    items: picker.items.map((item, index) => ({
      ...item,
      loading: loadingGroup.value === picker.id && loadingIndex.value === index,
    })),
  }))
})

const currentGradeLabel = computed(() => {
  if (props.pickers) {
    return props.pickers
      .find((picker) => picker.id === 'grades')
      ?.items.find(({ selected }) => !!selected)?.label
  }

  return ''
})

function getModal(id: string) {
  return PICKERS_MODALS[id]
}

function hasModal(id: string) {
  return Boolean(getModal(id))
}

function handleChange(pickerId: string, index: number) {
  loadingGroup.value = pickerId
  loadingIndex.value = index

  emit('change', pickerId, index)
}

function handleNewBatteryChange() {
  const gradePicker = pickersWithLoading.value.find(
    (picker) => picker.id === 'grades',
  )

  const selectedGradePicker = gradePicker?.items.findIndex(
    (item) => item.selected,
  )

  loadingGroup.value = 'grades'
  loadingIndex.value = selectedGradePicker
}
</script>
