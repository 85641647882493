<template>
  <div ref="root">
    <div class="flex items-center justify-center py-8">
      <IconPlus />
    </div>

    <div
      class="bg-surface-default-low border-static-default-hi rounded-sm relative border px-12 py-16"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { IconPlus } from '@ds/icons/IconPlus'
import { useIntersectionObserver } from '@vueuse/core'

const props = defineProps<{
  tracking: {
    block_name: string
    product_model: string
  }
}>()

const { trackProductBlockImpression } = useTracking()
const root = ref<HTMLElement | null>()

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackProductBlockImpression(props.tracking)
  }
})

onUnmounted(() => {
  stop()
})
</script>
