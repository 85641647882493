import { productAPI } from '@backmarket/http-api'

type StoriesItem = {
  duration: number
  url: string
  alt: string
  type: string
  source?: productAPI.GetProductResponse['images'][number]['source']
}

export const getStoriesItems = (
  images: productAPI.GetProductResponse['images'],
): Array<StoriesItem> => {
  return [
    ...images
      .filter((image) => image.source !== 'PARTNER')
      .slice(0, 3)
      .map(({ url, alt, source }) => ({
        duration: 2.5,
        url,
        alt,
        type: 'image',
        source,
      })),
    {
      duration: 10,
      url: 'https://front-office.statics.backmarket.com/browsing/the_story_of_refurbished.mp4',
      alt: '',
      type: 'video',
    },
    {
      duration: 17,
      url: 'https://front-office.statics.backmarket.com/browsing/unboxing.mp4',
      alt: '',
      type: 'video',
    },
    ...images
      .filter((image) => image.source === 'PARTNER')
      .map(({ url, alt, source }) => ({
        duration: 5,
        url,
        alt,
        type: 'image',
        source,
      })),
  ]
}
