import { type Grade, type LinkInternal, type Price } from '../../api-models'
import { type MobilePlanOffer } from '../../api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { createHttpEndpoint } from '../../utils'

/**
 * Returns a description of the product pickers to display on the product page
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/pickers/operation/getPickers}
 */
export const getPickers = createHttpEndpoint<GetPickersResponse>({
  method: 'GET',
  operationId: 'getPickers',
  path: '/bm/product/:productId/pickers',
})

export type PickersResponseItem = {
  trackingValue?: string
  label: string
  link?: LinkInternal
  selected: boolean
  available: boolean
  acquirable: boolean
  icon?: 'IconEyes' | 'IconSparkles'
  price?: Price
  color?: string
  goodDeal?: boolean
  grade?: Grade
  specialOfferType?: {
    type: number
    name: string
  }
  productId: string
  emphasis?: 'info' | 'accent'
  extraData?: MobilePlanOffer
}

export type Picker = {
  id: string
  trackingId?: string
  label: string
  items: Array<PickersResponseItem>
}

export type PickerWithExtraData = PickersResponseItem & {
  extraData: MobilePlanOffer
}

//  Extra data guard
export function hasItemExtraData(
  item: PickersResponseItem,
): item is PickerWithExtraData {
  return Boolean(item.extraData)
}

export type GetPickersResponse = {
  pickers: Array<Picker>
}
