<template>
  <Chip
    :class="{ 'order-first': isActive }"
    :is-active
    isInteractive
    :label="i18n(translations.advertisement)"
    @click="openDaModal"
  >
    <template #prefix>
      <IconSwap class="text-static-brand-mid" />
    </template>
  </Chip>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { openModal } from '@ds/components/ModalBase'
import { IconSwap } from '@ds/icons/IconSwap'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/constants'
import Chip from '~/scopes/product/components/Chip/Chip.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './TradeInChip.translations'

const props = defineProps<{
  swapStatus: string
  swapOffer?: Estimation
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const isActive = computed(() => {
  return (
    props.swapStatus === SWAP_SUCCESS &&
    !isEmpty(props.swapOffer) &&
    props.swapOffer?.hasOffer
  )
})

function openDaModal() {
  openModal(MODAL_NAMES.TRADE_IN)
  trackClick({
    zone: 'trade-in pill',
    name: 'trade-in info',
  })
}
</script>
