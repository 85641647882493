<template>
  <RevModal
    :name="MODAL_NAMES.TRADE_IN"
    :title="i18n(translations.modalTitle)"
    variant="panel"
  >
    <template #body>
      <div class="mb-24 text-center">
        <RevIllustration
          alt=""
          class="rounded-md w-full"
          :height="240"
          src="/img/product/funnel/step-trade-in.svg"
          :width="327"
        />
      </div>

      <h2 class="heading-1 my-24 text-center">
        {{ i18n(translations.title) }}
      </h2>

      <ContentRow
        class="!mb-20"
        :labels="[i18n(translations.item1Description)]"
        :title="i18n(translations.item1Label)"
      >
        <IconBadge />
      </ContentRow>

      <RevDivider class="my-20" />

      <ContentRow
        class="!mb-20 mt-0"
        :labels="[i18n(translations.item2Description)]"
        :title="i18n(translations.item2Label)"
      >
        <IconShoppingBag />
      </ContentRow>

      <RevDivider class="my-20" />

      <ContentRow
        class="!mb-20 mt-0"
        :labels="[i18n(translations.item3Description)]"
        :title="i18n(translations.item3Label)"
      >
        <IconPackage />
      </ContentRow>

      <RevDivider class="my-20" />

      <ContentRow
        class="!mb-20 mt-0"
        :labels="[i18n(translations.item4Description)]"
        :title="i18n(translations.item4Label)"
      >
        <IconMoney />
      </ContentRow>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'
import { IconBadge } from '@ds/icons/IconBadge'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconPackage } from '@ds/icons/IconPackage'
import { IconShoppingBag } from '@ds/icons/IconShoppingBag'

import ContentRow from '~/scopes/product/components/ContentRow/ContentRow.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './TradeInModal.translations'

const i18n = useI18n()
</script>
