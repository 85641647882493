<template>
  <div
    v-if="withTradeInChip || withVisibleByVerizonChip || withBouyguesChip"
    class="mt-12 md:relative"
  >
    <div
      ref="wrapper"
      class="flex flex-col gap-12 md:flex-row md:flex-nowrap md:items-center md:overflow-hidden"
    >
      <TradeInChip v-if="withTradeInChip" :swap-offer :swap-status />

      <VisibleByVerizonChip
        v-if="withVisibleByVerizonChip"
        tracking-zone="visible_info_title_section"
      />

      <BouyguesChip v-if="withBouyguesChip" :offers="bouyguesOffers" />
    </div>

    <div
      v-if="shouldShowPrevButton"
      class="absolute bottom-0 left-0 top-0 z-[1] hidden w-[104px] items-center justify-start md:flex"
      :class="$style.gradientPrevButton"
    >
      <RevButtonRounded
        :alternative-text="i18n(translations.less)"
        :icon="IconChevronLeft"
        variant="secondary"
        @click="onPrevClick"
      />
    </div>

    <div
      v-if="shouldShowNextButton"
      class="absolute bottom-0 right-0 top-0 z-[1] hidden w-[104px] items-center justify-end md:flex"
      :class="$style.gradientNextButton"
    >
      <RevButtonRounded
        :alternative-text="i18n(translations.more)"
        :icon="IconChevronRight"
        variant="secondary"
        @click="onNextClick"
      />
    </div>

    <RevDivider class="mb-12 mt-14 md:mb-16" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

import type { productAPI } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevDivider } from '@ds/components/Divider'
import { IconChevronLeft } from '@ds/icons/IconChevronLeft'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { getFilteredOffersFromVariation } from '~/scopes/product/utils/getFilteredItemsFromVariation'

import { hasVisibleByVerizon } from '../../utils/hasVisibleByVerizon'
import VisibleByVerizonChip from '../VisibleByVerizonChip/VisibleByVerizonChip.vue'

import translations from './AdvertisementChips.translations'
import BouyguesChip from './components/BouyguesChip/BouyguesChip.vue'
import TradeInChip from './components/TradeInChip/TradeInChip.vue'

const experiments = useExperiments()

const props = defineProps<{
  product: productAPI.GetProductResponse
  swapStatus: string
  swapOffer?: Estimation
}>()

const i18n = useI18n()
const wrapper = ref<HTMLElement | null>()
const displayedButton = ref(0)
const shouldShowPrevButton = ref(false)
const shouldShowNextButton = ref(false)

const withTradeInChip = computed(() => {
  return props.product.isSwapEligible
})

const withVisibleByVerizonChip = computed(() =>
  hasVisibleByVerizon(props.product.includedServiceOffers.partnerPromoCodes),
)

const bouyguesOffers = computed(() => {
  const offers = props.product.includedServiceOffers.mobilePlans.filter(
    ({ partner }) => partner.name === 'BOUYGUES_TELECOM',
  )

  return getFilteredOffersFromVariation(
    offers,
    experiments['experiment.ppBouyguesOffersFilter'],
  )
})

const withBouyguesChip = computed(() => bouyguesOffers.value.length > 0)

function updateShouldDisplayPrevButton() {
  if (wrapper.value) {
    shouldShowPrevButton.value =
      wrapper.value.scrollWidth > wrapper.value.offsetWidth &&
      displayedButton.value > 0
  }
}

function updateShouldDisplayNextButton() {
  const buttonsLength = wrapper.value?.querySelectorAll('button').length
  if (wrapper.value && buttonsLength) {
    shouldShowNextButton.value =
      wrapper.value.scrollWidth > wrapper.value.offsetWidth &&
      displayedButton.value < buttonsLength - 1
  }
}

function scrollTo(index: number) {
  if (wrapper.value) {
    const items = Array.from(wrapper.value.querySelectorAll('button')) || []
    items[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
    displayedButton.value = index
  }

  updateShouldDisplayPrevButton()
  updateShouldDisplayNextButton()
}

function onPrevClick() {
  scrollTo(displayedButton.value - 1)
}

function onNextClick() {
  scrollTo(displayedButton.value + 1)
}

onMounted(() => {
  updateShouldDisplayNextButton()
  window.addEventListener('resize', updateShouldDisplayPrevButton)
  window.addEventListener('resize', updateShouldDisplayNextButton)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateShouldDisplayPrevButton)
  window.removeEventListener('resize', updateShouldDisplayNextButton)
})
</script>

<style module>
.gradientPrevButton {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 9.09%,
    rgba(255, 255, 255, 0.29) 27.86%,
    #fff 58.7%
  );
}

.gradientNextButton {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 9.09%,
    rgba(255, 255, 255, 0.29) 27.86%,
    #fff 58.7%
  );
}
</style>
