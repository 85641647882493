<template>
  <AdditionalBlock :tracking="tracking">
    <div class="flex grow justify-between gap-12">
      <div>
        <h3 class="heading-3">
          {{ i18n(translations.tradeInProposalName) }}
        </h3>
        <p class="body-2">
          {{ device }}
        </p>
      </div>
      <div>
        <h3 class="heading-3">
          {{ price }}
        </h3>
        <p class="body-2">
          {{ i18n(translations.tradeInProposalPrice) }}
        </p>
      </div>
    </div>
    <RevDivider class="my-8" />
    <div class="flex items-center">
      <RevIllustration
        alt=""
        class="rounded-sm"
        :height="54"
        src="/img/product/funnel/guidance-trade-in.svg"
        :width="54"
      />
      <p class="caption pl-12">
        {{
          i18n(translations.tradeInProposalDescription, {
            price,
          })
        }}
      </p>
    </div>
  </AdditionalBlock>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'

import AdditionalBlock from '../AdditionalBlock/AdditionalBlock.vue'

import translations from './TradeIn.translations'

const i18n = useI18n()

defineProps<{
  device?: string | null
  price?: string
  tracking: {
    block_name: string
    product_model: string
  }
}>()
</script>
