<template>
  <div>
    <RevModal
      :name="MODAL_NAMES.COPY_LEVY"
      :title="i18n(translations.header)"
      variant="panel"
    >
      <template #body>
        <div class="text-center">
          <RevIllustration
            alt=""
            class="rounded-md w-full"
            :height="240"
            src="/img/product/copy-levy-modal.png"
            :width="327"
          />
        </div>
        <h2 class="heading-1 mt-24 text-center">
          {{ i18n(translations.title) }}
        </h2>
        <p class="mt-16">
          {{ i18n(translations.paragraph1) }}
        </p>
        <p class="mt-16">
          {{ i18n(translations.paragraph2) }}
        </p>
      </template>
    </RevModal>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'

import { MODAL_NAMES } from '../../../../constants'

import translations from './CopyLevyModal.translations'

const i18n = useI18n()
</script>
