export default {
  monthlyPricePlan: {
    id: 'installment_payment_monthly_price_plan',
    defaultMessage:
      'As low as {pricePerMonth, html} for {duration} months at {apr}% APR',
  },
  pricePerMonth: {
    id: 'installment_payment_price_per_month',
    defaultMessage: '{amount}/mo',
  },
}
