<template>
  <div class="mt-16">
    <div
      class="mb-8 flex flex-row"
      :class="{
        'justify-between': showEcoBlock,
        'justify-end': !showEcoBlock,
      }"
    >
      <EcoBlockAnchor v-if="showEcoBlock" />
      <FavoritesToggle
        class="bg-static-default-low rounded-full"
        :grade-id="currentGrade?.backboxGrade.value"
        is-rounded
        :offer-type="offerType"
        :price="currentGrade?.price"
        :product-uuid="product.id"
        :tracking-info="tracking.product.value"
        zone="details"
      />
    </div>

    <h1 class="heading-1 mb-8">
      {{ product.rawTitle }}
    </h1>

    <RatingInfo
      v-if="rating"
      anchor-id="reviews"
      class="mb-8"
      :rating="rating"
    />
  </div>
</template>

<script lang="ts" setup>
import { productAPI } from '@backmarket/http-api'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'

import FavoritesToggle from '~/scopes/dashboard/favorites/toggle/FavoritesToggle.vue'
import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import { useProductTracking } from '../../composables/useProductTracking'
import { useUrlParams } from '../../composables/useUrlParams'

import EcoBlockAnchor from './components/EcoBlockAnchor/EcoBlockAnchor.vue'

withDefaults(
  defineProps<{
    currentGrade?: productAPI.GetBestOffersResponse[number] | null
    product: productAPI.GetProductResponse
    showEcoBlock?: boolean
    rating?: Rating | null
    tracking: ReturnType<typeof useProductTracking>
  }>(),
  {
    currentGrade: null,
    showEcoBlock: false,
    rating: null,
  },
)

const { offerType } = useUrlParams()
</script>
