import { h } from 'vue'

import {
  type Picker,
  type PickerWithExtraData,
  type PickersResponseItem,
  hasItemExtraData,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product'
import type { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'
import { MODAL_NAMES } from '~/scopes/product/constants'
import { getFilteredItemsFromVariation } from '~/scopes/product/utils/getFilteredItemsFromVariation'

import BouyguesPickerAddon from '../../components/BouyguesPickerAddon/BouyguesPickerAddon.vue'
import type { Step } from '../types'
import { mapOption } from '../utils'

import translations from './translations'

const getMobilePlanPrices = (item: PickerWithExtraData) => {
  const keepPhoneNumberPriceOption = item.extraData.priceOptions.find(
    ({ option }) => option === 'KEEP_PHONE_NUMBER',
  )

  if (!keepPhoneNumberPriceOption) return []

  return keepPhoneNumberPriceOption.prices
}

export function getGuidance(items: PickerWithExtraData[]) {
  const areBothPlanDisabled = items.every(({ available }) => !available)
  if (areBothPlanDisabled) return undefined

  const hasOnlyBAndYouOffers = items.every(
    (item) => item.extraData.brand === 'B&You',
  )

  return {
    label: hasOnlyBAndYouOffers
      ? translations.guidanceMobilePlanBandYou
      : translations.guidanceMobilePlanBouyguesTelecom,
    icon: BouyguesLogo,
    modalName: MODAL_NAMES.BOUYGUES,
  }
}

export function getSubLabel(
  i18n: ReturnType<typeof useI18n>,
  option: PickerWithExtraData,
) {
  const prices = getMobilePlanPrices(option)
  if (prices.length === 0) return null

  const firstPeriodPrice = i18n.price(prices[0].price)
  const isSamePrice = prices.length === 1

  const { getFormattedDuration } = useFormatMonthsYearsDuration()

  const enrollmentPeriod =
    option.extraData.enrollmentPeriod === 0
      ? i18n(translations.withoutEnrollmentPeriod)
      : i18n(translations.withEnrollmentPeriod, {
          duration: getFormattedDuration(option.extraData.enrollmentPeriod),
        })

  if (isSamePrice) {
    return i18n(translations.mobilePlanOptionDescriptionSamePrice, {
      price: firstPeriodPrice,
    }).concat(enrollmentPeriod)
  }

  return i18n(translations.mobilePlanOptionDescriptionPriceFirstYear, {
    price: firstPeriodPrice,
  }).concat(enrollmentPeriod)
}

export function getMobilePlanOption(
  item: PickersResponseItem,
  i18n: ReturnType<typeof useI18n>,
) {
  if (!hasItemExtraData(item)) return mapOption(item)

  const bouyguesOption = {
    ...item,
    subLabel: getSubLabel(i18n, item),
    addon:
      item.trackingValue !== 'smartphone_only' &&
      h(BouyguesPickerAddon, { offer: item.extraData }),
    tag: i18n(translations.mobilePlanSubsisdy, {
      subsidy: i18n.price(item.extraData.subsidy, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }),
  }

  return mapOption(bouyguesOption)
}

export function getMobilePlanStep(
  i18n: ReturnType<typeof useI18n>,
  ppBouyguesOffersFilter: string,
  picker?: Picker,
): Step | null {
  if (!picker || picker.items.length === 0) return null

  // We run an A/B/C test which is filtering the items (see https://backmarket.atlassian.net/wiki/spaces/2C/pages/3960307861/2024-07-31+-+Trade-in+A+B+test+and+Bouygues+new+offers#%3ABouygues%3A-Bouygues-new-offer)
  const items = getFilteredItemsFromVariation(
    picker.items,
    ppBouyguesOffersFilter,
  )

  const lastItem = items[items.length - 1]

  return {
    id: 'mobile_plan',
    type: 'service',
    title: translations.titleStepMobilePlan,
    titleEmphasis: translations.titleEmphasisStepMobilePlan,
    titlePrice: lastItem.extraData?.subsidy,
    trackingId: picker.trackingId,
    desktopIllustration: '/img/product/funnel/step-mobile-plan-bouygues.svg',
    guidance: getGuidance(items.filter(hasItemExtraData)),
    options: items.map((item) => getMobilePlanOption(item, i18n)),
  }
}
