export default {
  description: {
    id: 'product_page_customization_condition_description_mobile',
    defaultMessage: 'All guaranteed 100% functional',
  },
  info_block: {
    id: 'product_page_customization_condition_info_block',
    defaultMessage:
      'Appearance describes the look of the device. Fair, Good, Excellent — all 100% functional. These images are examples only.',
  },
  info_block_with_premium: {
    id: 'product_page_customization_condition_info_block_with_premium',
    defaultMessage:
      'Appearance describes the look of the device. Fair, Good, Excellent, Premium — all 100% functional. These images are examples only.',
  },
}
