import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  enrollmentPeriod: {
    id: 'product_page_customization_result_mobile_plan_enrollment',
    defaultMessage: "{duration} d'engagement",
  },
  period1Price: {
    id: 'product_page_customization_result_mobile_plan_period_1_price',
    defaultMessage: '{price}/mois',
  },
  period1Duration: {
    id: 'product_page_customization_result_mobile_plan_period_1_duration',
    defaultMessage: 'pendant {duration}',
  },
  period2Price: {
    id: 'product_page_customization_result_mobile_plan_period_2_price',
    defaultMessage: 'puis {price}',
  },
} as Record<string, I18nDefinition>
