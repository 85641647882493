import {
  type Grade,
  type Link,
  type LinkInternal,
  type Price,
} from '../../api-models'
import { type PaymentMethod } from '../../api-specs-payment/payment'
import { createHttpEndpoint } from '../../utils'

/**
 * Returns the buyboxed listings for the requested product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/bestOffers/operation/getBestOffersV3}
 */
export const getBestOffers = createHttpEndpoint<GetBestOffersResponse>({
  method: 'GET',
  operationId: 'getBestOffersV3',
  path: '/bm/product/:productId/v3/best_offers',
})

export type GetBestOffersResponse = Array<{
  id: number
  publicId: string
  link: Link
  paymentMethods: Array<PaymentMethod>
  installmentPaymentAsLowAs?: Price | null
  installmentMonthlyPaymentPlan?: {
    annualPercentageRate: number
    monthlyPrice: Price
    numberOfMonths: number
  }
  price: Price
  copyLevy?: Price | null
  fee?: {
    serviceFee: Price
    priceWithServiceFee: Price
    buyingGuideServiceFee: Link
  } | null
  reassurance: {
    isGoodDeal: boolean
    stock: {
      stockForCommonDisplay: number
      stockRawValue: number
      pressureStockLimit: number
    }
    tags: Array<string>
    discount: {
      price?: Price
      rate?: string
      isDisplayed: boolean
    }
    defaultWarrantyDelay: string
    shipping?: {
      free?: {
        delayInHours: number
        earliestArrivalDate: string
        latestArrivalDate: string
        provider: string
      }
      express?: {
        price: Price
        delayInHours: number
        earliestArrivalDate: string
        latestArrivalDate: string
        provider: string
      }
    }
  }
  backboxGrade: Grade
  isDisabled: boolean
  isSelectedByDefault: boolean
  comment: string
  merchant: {
    id: string
    legacyId: number
    company: string
    description?: string
    country: string
    countryCode: string
    shippedFrom: string
    sellerSinceYear: number
    link: LinkInternal
    impactChampion?: {
      id: number
      impactLabel: string
      link: Link
    }
  }
  eligibleToInstallmentPayment: boolean
  eligibleToKlarna: boolean
  collection: Array<{
    id: number
    label: string
  }>
  skinnyBannerId?: number | null
  newBattery?: {
    status: 'not-available' | 'available' | 'disabled'
    offerType?: string
  }
}>
