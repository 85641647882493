import type { LocationQuery } from 'vue-router'

import type { Price } from '@backmarket/http-api'

interface DropdownDataListItem {
  price: Omit<Price, 'currency'>
  id: string
  value: LocationQuery
}

type DropdownDataItem = Omit<DropdownDataListItem, 'id'>

interface DropdownDataGlobal {
  EU: Array<DropdownDataListItem>
  NA: Array<DropdownDataListItem>
  AP: Array<DropdownDataListItem>
}

const dropdownDataEU: Array<DropdownDataItem> = [
  {
    price: {
      amount: '755',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14 Pro Max',
    },
  },
  {
    price: {
      amount: '660',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14 Pro',
    },
  },
  {
    price: {
      amount: '590',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14 Plus',
    },
  },
  {
    price: {
      amount: '500',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14',
    },
  },
  {
    price: {
      amount: '605',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13 Pro Max',
    },
  },
  {
    price: {
      amount: '590',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13 Pro',
    },
  },
  {
    price: {
      amount: '450',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13 Mini',
    },
  },
  {
    price: {
      amount: '465',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13',
    },
  },
  {
    price: {
      amount: '440',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12 Pro Max',
    },
  },
  {
    price: {
      amount: '420',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12 Pro',
    },
  },
  {
    price: {
      amount: '440',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12 mini',
    },
  },
  {
    price: {
      amount: '345',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12',
    },
  },
  {
    price: {
      amount: '300',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 11 Pro Max',
    },
  },
  {
    price: {
      amount: '260',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 11 Pro',
    },
  },
  {
    price: {
      amount: '240',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 11',
    },
  },
  {
    price: {
      amount: '185',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone XS Max',
    },
  },
  {
    price: {
      amount: '155',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone XS',
    },
  },
  {
    price: {
      amount: '170',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone XR',
    },
  },
  {
    price: {
      amount: '120',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone X',
    },
  },
  {
    price: {
      amount: '300',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone SE (2022)',
    },
  },
  {
    price: {
      amount: '175',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone SE (2020)',
    },
  },
  {
    price: {
      amount: '105',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 8 Plus',
    },
  },
  {
    price: {
      amount: '75',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 8',
    },
  },
  {
    price: {
      amount: '60',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 7 Plus',
    },
  },
  {
    price: {
      amount: '35',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 7',
    },
  },
  {
    price: {
      amount: '25',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 6S',
    },
  },
  {
    price: {
      amount: '445',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 7',
      model: 'Pixel 7 Pro',
    },
  },
  {
    price: {
      amount: '220',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 7',
      model: 'Pixel 7a',
    },
  },
  {
    price: {
      amount: '250',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 7',
      model: 'Pixel 7',
    },
  },
  {
    price: {
      amount: '250',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 6',
      model: 'Pixel 6 Pro',
    },
  },
  {
    price: {
      amount: '150',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 6',
      model: 'Pixel 6A',
    },
  },
  {
    price: {
      amount: '550',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Fold5',
    },
  },
  {
    price: {
      amount: '455',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Fold 4',
    },
  },
  {
    price: {
      amount: '340',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Fold 3',
    },
  },
  {
    price: {
      amount: '460',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Flip5',
    },
  },
  {
    price: {
      amount: '255',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Flip 4',
    },
  },
  {
    price: {
      amount: '210',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Flip 3',
    },
  },
  {
    price: {
      amount: '640',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S23 Ultra',
    },
  },
  {
    price: {
      amount: '475',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S23+',
    },
  },
  {
    price: {
      amount: '405',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S23',
    },
  },
  {
    price: {
      amount: '450',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22 Ultra 5G',
    },
  },
  {
    price: {
      amount: '440',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22 Ultra',
    },
  },
  {
    price: {
      amount: '310',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22+ 5G',
    },
  },
  {
    price: {
      amount: '335',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22+',
    },
  },
  {
    price: {
      amount: '295',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22 5G',
    },
  },
  {
    price: {
      amount: '295',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22',
    },
  },
  {
    price: {
      amount: '255',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21+ 5G',
    },
  },
  {
    price: {
      amount: '355',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21 Ultra 5G',
    },
  },
  {
    price: {
      amount: '215',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21 FE',
    },
  },
  {
    price: {
      amount: '210',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21 5G',
    },
  },
  {
    price: {
      amount: '150',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20+ 5G',
    },
  },
  {
    price: {
      amount: '225',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20 Ultra',
    },
  },
  {
    price: {
      amount: '130',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20 FE 5G Dual Sim',
    },
  },
  {
    price: {
      amount: '150',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20',
    },
  },
  {
    price: {
      amount: '80',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S10e',
    },
  },
  {
    price: {
      amount: '145',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S10+',
    },
  },
  {
    price: {
      amount: '155',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S10',
    },
  },
  {
    price: {
      amount: '295',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Note',
      model: 'Galaxy Note 20 Ultra',
    },
  },
  {
    price: {
      amount: '220',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Note',
      model: 'Galaxy Note 20',
    },
  },
  {
    price: {
      amount: '65',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S9',
    },
  },
  {
    price: {
      amount: '55',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S8',
    },
  },
  {
    price: {
      amount: '210',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A53 5G',
    },
  },
  {
    price: {
      amount: '100',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A51',
    },
  },
  {
    price: {
      amount: '45',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A40',
    },
  },
  {
    price: {
      amount: '45',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A20e',
    },
  },
  {
    price: {
      amount: '80',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A13',
    },
  },
  {
    price: {
      amount: '65',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A12',
    },
  },
]

const dropdownDataNA: Array<DropdownDataItem> = [
  {
    price: {
      amount: '670',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14 Pro Max',
    },
  },
  {
    price: {
      amount: '540',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14 Pro',
    },
  },
  {
    price: {
      amount: '400',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14 Plus',
    },
  },
  {
    price: {
      amount: '455',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 14',
    },
  },
  {
    price: {
      amount: '495',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13 Pro Max',
    },
  },
  {
    price: {
      amount: '495',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13 Pro',
    },
  },
  {
    price: {
      amount: '330',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13 Mini',
    },
  },
  {
    price: {
      amount: '355',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 13',
    },
  },
  {
    price: {
      amount: '340',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12 Pro Max',
    },
  },
  {
    price: {
      amount: '380',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12 Pro',
    },
  },
  {
    price: {
      amount: '215',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12 mini',
    },
  },
  {
    price: {
      amount: '270',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 12',
    },
  },
  {
    price: {
      amount: '295',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 11 Pro Max',
    },
  },
  {
    price: {
      amount: '255',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 11 Pro',
    },
  },
  {
    price: {
      amount: '225',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 11',
    },
  },
  {
    price: {
      amount: '200',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone XS Max',
    },
  },
  {
    price: {
      amount: '140',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone XS',
    },
  },
  {
    price: {
      amount: '140',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone XR',
    },
  },
  {
    price: {
      amount: '125',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone X',
    },
  },
  {
    price: {
      amount: '170',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone SE (2022)',
    },
  },
  {
    price: {
      amount: '130',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone SE (2020)',
    },
  },
  {
    price: {
      amount: '119',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 8 Plus',
    },
  },
  {
    price: {
      amount: '80',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 8',
    },
  },
  {
    price: {
      amount: '65',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 7 Plus',
    },
  },
  {
    price: {
      amount: '40',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 7',
    },
  },
  {
    price: {
      amount: '20',
    },
    value: {
      category: 'smartphone',
      brand: 'Apple',
      model: 'iPhone 6S',
    },
  },
  {
    price: {
      amount: '250',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 7',
      model: 'Pixel 7 Pro',
    },
  },
  {
    price: {
      amount: '220',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 7',
      model: 'Pixel 7',
    },
  },
  {
    price: {
      amount: '165',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 6',
      model: 'Pixel 6 Pro',
    },
  },
  {
    price: {
      amount: '130',
    },
    value: {
      category: 'smartphone',
      brand: 'Google',
      model_family: 'Pixel 6',
      model: 'Pixel 6A',
    },
  },
  {
    price: {
      amount: '455',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Fold 4',
    },
  },
  {
    price: {
      amount: '260',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Fold 3',
    },
  },
  {
    price: {
      amount: '190',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Flip 4',
    },
  },
  {
    price: {
      amount: '140',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Z',
      model: 'Galaxy Z Flip 3',
    },
  },
  {
    price: {
      amount: '425',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22 Ultra 5G',
    },
  },
  {
    price: {
      amount: '310',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22+ 5G',
    },
  },
  {
    price: {
      amount: '235',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S22 5G',
    },
  },
  {
    price: {
      amount: '230',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21+ 5G',
    },
  },
  {
    price: {
      amount: '290',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21 Ultra 5G',
    },
  },
  {
    price: {
      amount: '165',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S21 5G',
    },
  },
  {
    price: {
      amount: '135',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20+ 5G',
    },
  },
  {
    price: {
      amount: '190',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20 Ultra',
    },
  },
  {
    price: {
      amount: '100',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20 FE 5G Dual Sim',
    },
  },
  {
    price: {
      amount: '125',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S20',
    },
  },
  {
    price: {
      amount: '45',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S10e',
    },
  },
  {
    price: {
      amount: '105',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S10+',
    },
  },
  {
    price: {
      amount: '85',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S10',
    },
  },
  {
    price: {
      amount: '305',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Note',
      model: 'Galaxy Note 20 Ultra',
    },
  },
  {
    price: {
      amount: '170',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy Note',
      model: 'Galaxy Note 20',
    },
  },
  {
    price: {
      amount: '55',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S9',
    },
  },
  {
    price: {
      amount: '30',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy S',
      model: 'Galaxy S8',
    },
  },
  {
    price: {
      amount: '100',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A53 5G',
    },
  },
  {
    price: {
      amount: '35',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A51',
    },
  },
  {
    price: {
      amount: '25',
    },
    value: {
      category: 'smartphone',
      brand: 'Samsung',
      model_family: 'Galaxy A',
      model: 'Galaxy A12',
    },
  },
]

const withID = (item: DropdownDataItem): DropdownDataListItem => {
  return {
    ...item,
    id:
      item.value.model && typeof item.value.model === 'string'
        ? item.value.model.replace(/ /g, '_')
        : 'defaultId',
  }
}

export const dropdownData: DropdownDataGlobal = {
  EU: dropdownDataEU.map(withID),
  NA: dropdownDataNA.map(withID),
  AP: [],
}
