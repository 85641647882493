<template>
  <div>
    <Accessories
      v-if="
        comesWith.items.length > 0 ||
        hasVisibleByVerizon(product.includedServiceOffers.partnerPromoCodes)
      "
      :comes-with="comesWith"
      :partner-promo-codes="product.includedServiceOffers.partnerPromoCodes"
    />

    <SellerComment
      v-if="currentGrade && currentGrade.comment"
      :comment="currentGrade.comment"
    />

    <SwapBlock
      v-if="currentGrade && shouldDisplaySwap"
      class="mb-32"
      :grade-id="currentGrade.id"
      :modal-name="MODAL_NAMES.SWAP_PRODUCT"
      :model="product.rawTitle"
      :price="currentGrade.price"
      :product-category="productCategory"
      :swap-listings="swapListings"
      :swap-offer="swapOffer"
      :swap-redirection="PRODUCT.HOME"
      :swap-status="swapStatus"
      tracking-label="swap_from_pp"
    />

    <AdditionalInfo
      v-if="technicalSpecifications && currentGrade"
      :listing-id="currentGrade.id"
      :technical-specifications="technicalSpecifications"
    />

    <Seller v-if="currentGrade && shouldDisplaySeller" :grade="currentGrade" />

    <Vat v-if="isVatIncluded" class="my-32 md:my-56" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapBlock from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.vue'
import { SWAP_DISABLED } from '~/scopes/buyback/swap/components/SwapBlock/constants'

import { PRODUCT } from '../../route-names'
import { hasVisibleByVerizon } from '../../utils/hasVisibleByVerizon'
import Accessories from '../Accessories/Accessories.vue'
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo.vue'
import Seller from '../Seller/Seller.vue'
import SellerComment from '../SellerComment/SellerComment.vue'
import Vat from '../Vat/Vat.vue'

const marketPlace = useMarketplace()

const props = withDefaults(
  defineProps<{
    currentGrade?: productAPI.GetBestOffersResponse[number] | null
    hasSwap: boolean
    product: productAPI.GetProductResponse
    productCategory: string
    showSellerBlock?: boolean
    shouldDisplayVat?: boolean
    swapOffer?: Estimation
    swapListings?: Array<number>
    swapStatus?: string
    technicalSpecifications?: productAPI.GetTechnicalSpecificationsResponse | null
  }>(),
  {
    currentGrade: null,
    showSellerBlock: true,
    shouldDisplayVat: true,
    swapOffer: undefined,
    swapListings: () => [],
    swapStatus: '',
    technicalSpecifications: undefined,
  },
)

const comesWith = computed(() => {
  return (
    props.technicalSpecifications?.comesWith ?? {
      items: [],
      displayAccessoryExclusionsModal: false,
    }
  )
})

const shouldDisplaySwap = computed(() => {
  return props.hasSwap && props.swapStatus && props.swapStatus !== SWAP_DISABLED
})

const isAccessory = computed(() => {
  return props.product.type === 'accessory'
})

const shouldDisplaySeller = computed(() => {
  return (
    !isAccessory.value && props.currentGrade?.merchant && props.showSellerBlock
  )
})

const isVatIncluded = computed(() => {
  return marketPlace.features.vatIncluded && props.shouldDisplayVat
})
</script>
