<template>
  <span v-if="label" class="body-2-bold whitespace-nowrap">
    {{ label }}
  </span>
</template>

<script lang="ts" setup>
/**
 * For now this component only displays "before trade-in" mention, but in a very near future it'll also
 * display "after trade-in" with due today mention, or "with mobile plan", etc.
 */
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { useUrlParams } from '~/scopes/product/composables/useUrlParams'

import { COUNTRIES_WITH_TRADE_IN_PRICE_LABEL } from '../../Price.constants'

import translations from './PriceLabel.translations'

const i18n = useI18n()
const { mobilePlan } = useUrlParams()

const {
  market: { countryCode },
} = useMarketplace()

const label = computed(() => {
  if (mobilePlan.value) {
    return i18n(translations.withMobilePlan)
  }

  if (COUNTRIES_WITH_TRADE_IN_PRICE_LABEL.includes(countryCode)) {
    return i18n(translations.beforeTradeIn)
  }

  return null
})
</script>
