<template>
  <div v-if="shouldDisplayNewBattery" class="relative mt-12">
    <div v-if="shouldDisplayToggle">
      <RevToggle
        :checked="checked"
        class="rounded-sm mb-32 border px-16 py-12"
        :class="[toggleClassNames, $style.toggleOverride]"
        label=""
        position="sticked"
        @change="onChange"
      >
        <div class="flex items-center" :class="labelClassNames">
          <div class="rounded-sm mr-16 border p-4" :class="iconClassNames">
            <IconBattery />
          </div>

          <div class="flex flex-col gap-1">
            <span>{{ label }}</span>
            <RevLink class="body-2-bold text-left" @click="openBatteryModal">
              {{ i18n(translations.trigger) }}
            </RevLink>
          </div>
        </div>
      </RevToggle>
    </div>

    <div v-if="shouldDisplayDisabledToggle">
      <RevToggle
        :checked="checked"
        class="rounded-sm mb-32 border px-16 py-12"
        :class="[toggleClassNames, $style.toggleOverride]"
        :disabled="true"
        label=""
        position="sticked"
        @change="onChange"
      >
        <div class="flex items-center" :class="labelClassNames">
          <div class="rounded-sm mr-16 border p-4" :class="iconClassNames">
            <IconBattery />
          </div>

          <div class="flex flex-col items-baseline gap-1">
            <span>{{ i18n(translations.disabledV2, { grade }) }}</span>
            <div>
              <RevLink
                class="!text-action-default-hi text-left"
                @click="openBatteryModal"
              >
                {{ i18n(translations.compare) }}
              </RevLink>
            </div>
          </div>
        </div>
      </RevToggle>
    </div>

    <div v-if="shouldDisplayEducation">
      <RevLink
        class="w-full cursor-pointer"
        :underlined="false"
        @click="openBatteryModal"
      >
        <div
          class="bg-static-info-mid rounded-lg flex items-center p-16 text-left"
        >
          <IconBattery class="shrink-0" />
          <span class="body-2 grow px-12">
            {{ i18n(translations.education) }}
          </span>
          <IconChevronRight class="shrink-0" />
        </div>
      </RevLink>
    </div>

    <Modal />
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { computed, ref, useCssModule } from 'vue'

import type { LinkInternal } from '@backmarket/http-api/src/api-models'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { parse } from '@backmarket/utils/query-string/parse'
import { stringify } from '@backmarket/utils/query-string/stringify'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevToggle } from '@ds/components/Toggle'
import { IconBattery } from '@ds/icons/IconBattery'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { MODAL_NAMES } from '~/scopes/product/constants'

import { useUrlParams } from '../../../../composables/useUrlParams'
import { hashToQueryLink } from '../../../../utils/hashToQueryLink'

import translations from './NewBattery.translations'
import Modal from './components/Modal/Modal.vue'

const props = withDefaults(
  defineProps<{
    grade?: string
    offerType?: string | null
    productCategory?: number
    status?: string | null
  }>(),
  {
    grade: '',
    offerType: null,
    productCategory: 0,
    status: null,
  },
)

const i18n = useI18n()
const { offerType: offerTypeURL } = useUrlParams()
const { trackClick } = useTracking()
const route = useRoute()
const router = useRouter()
const style = useCssModule()
const animate = ref(false)
const emit = defineEmits(['change'])

const shouldDisplayNewBattery = computed(() => {
  // new battery status is sent for EVERY product once opened in a country. As long as we don't have a
  // backend filter on categories (they have one on... brands), we'll need to have this check.
  if (props.productCategory !== 2) {
    return false
  }

  return props.status !== null
})

const checked = computed(() => {
  if (props.offerType === null) {
    return false
  }

  return offerTypeURL.value === parseInt(props.offerType, 10)
})

const shouldDisplayDisabledToggle = computed(() => {
  return props.status === 'disabled'
})

const label = computed(() => {
  return i18n(
    shouldDisplayDisabledToggle.value
      ? translations.disabled
      : translations.label,
    { grade: props.grade },
  )
})

const toggleClassNames = computed(() => {
  if (shouldDisplayDisabledToggle.value) {
    return 'border-action-default-low-disabled bg-action-default-low-disabled'
  }

  if (checked.value) {
    return 'border-static-default-hi bg-action-default-mid'
  }

  return [
    'border-action-default-hi bg-action-default-low cursor-pointer',
    animate.value && style.container_animation,
  ]
})

const labelClassNames = computed(() => {
  if (shouldDisplayDisabledToggle.value) {
    return 'text-action-default-hi-disabled'
  }

  return 'cursor-pointer text-action-default-hi'
})

const iconClassNames = computed(() => {
  if (shouldDisplayDisabledToggle.value) {
    return 'border-action-default-low-disabled bg-surface-default-hi'
  }

  if (checked.value) {
    return 'border-action-default-low bg-static-success-mid'
  }

  return 'border-action-default-hi bg-surface-default-hi'
})

const shouldDisplayToggle = computed(() => {
  return props.status === 'available'
})

const shouldDisplayEducation = computed(() => {
  return props.status === 'not-available'
})

function openBatteryModal() {
  openModal(MODAL_NAMES.NEW_BATTERY)
}

async function onChange(event: Event) {
  const target = event?.target as HTMLInputElement | null

  animate.value = Boolean(target?.checked)
  emit('change')

  // On activation, let's wait for the animation to be completed before refreshing the data
  const timeout = target?.checked ? 400 : 0
  await new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })

  trackClick({
    zone: 'new_battery',
    name: 'new_battery',
    value: target?.checked ? 'activate' : 'deactivate',
  })

  const link = {
    ...route,
    type: 'internal',
    query: route.query,
    hash: {
      ...parse(route.hash),
      offerType:
        target?.checked && props.offerType !== null
          ? props.offerType
          : undefined,
    },
  } as LinkInternal

  const newRoute = hashToQueryLink(link)

  // Let's update the hash with the right offerType param. Product.vue page component will keep the data fresh
  router.push({
    ...newRoute,
    hash: `#${stringify({
      ...newRoute.hash,
    })}`,
  })
}
</script>

<style module>
.container_animation {
  background: linear-gradient(70deg, #cefbdc 50%, transparent 0);
  background-size: 210% 100%;
  background-position: right;
  animation: makeItfadeIn ease-out 0.4s;
}

@keyframes makeItfadeIn {
  100% {
    background-position: left;
  }
}

/* Should be fixed in the DS, but it's currently in a code freeze state */
/* https://backmarket.atlassian.net/servicedesk/customer/portal/57/DSS-188 */
.toggleOverride input[disabled] + span {
  cursor: default;
}
</style>
