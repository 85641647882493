export default {
  title: {
    id: 'product_sim_modal_title',
    defaultMessage: 'SIM options',
  },
  introTitle: {
    id: 'product_sim_modal_intro_title',
    defaultMessage: 'Which SIM type to get?',
  },
  introText: {
    id: 'product_sim_modal_intro_text',
    defaultMessage:
      'When you choose a SIM option for your device, make sure it’s supported by your carrier. SIMs allow you to activate a cellular plan from your carrier and hold info like your phone number.',
  },

  compareTitle: {
    id: 'product_sim_modal_compare_title',
    defaultMessage: 'There are 2 types of SIMs',
  },
  compareSim: {
    id: 'product_sim_modal_compare_sim_title',
    defaultMessage: 'SIM card',
  },
  compareEsim: {
    id: 'product_sim_modal_compare_esim_title',
    defaultMessage: 'eSIM',
  },
  compareSimText: {
    id: 'product_sim_modal_compare_sim_text',
    defaultMessage: 'A physical chip inserted into your phone',
  },
  compareEsimText: {
    id: 'product_sim_modal_compare_esim_text',
    defaultMessage: 'The digital version*',
  },
  compareEsimNote: {
    id: 'product_sim_modal_compare_esim_note',
    defaultMessage:
      'eSIMs* are more secure and reliable than a physical SIM card. They are impossible to lose, and offer a more flexible and convenient way to switch carriers or plans.',
  },

  comboTitle: {
    id: 'product_sim_modal_combo_title',
    defaultMessage: 'With combinations to choose from to suit your style',
  },
  comboSubtitle1: {
    id: 'product_sim_modal_combo_subtitle_1',
    defaultMessage: 'SIM combo',
  },
  comboSubtitle2: {
    id: 'product_sim_modal_combo_subtitle_2',
    defaultMessage: 'What it’ll look like',
  },
  option1: {
    id: 'product_sim_modal_combo_option_esim',
    defaultMessage: 'eSIM',
  },
  option1Desc: {
    id: 'product_sim_modal_combo_option_esim_desc',
    defaultMessage: '1 virtual slot',
  },
  option2: {
    id: 'product_sim_modal_combo_option_single_sim',
    defaultMessage: 'Single-SIM',
  },
  option2Desc: {
    id: 'product_sim_modal_combo_option_single_sim_desc',
    defaultMessage: '1 physical slot',
  },
  option3: {
    id: 'product_sim_modal_combo_option_sim_esim',
    defaultMessage: 'Single-SIM + eSIM',
  },
  option3Desc: {
    id: 'product_sim_modal_combo_option_sim_esim_desc',
    defaultMessage: '1 physical + 1 virtual slot',
  },
  option4: {
    id: 'product_sim_modal_combo_option_dual_esim',
    defaultMessage: 'Dual eSIM',
  },
  option4Desc: {
    id: 'product_sim_modal_combo_option_dual_esim_desc',
    defaultMessage: '2 virtual slots',
  },
  option5: {
    id: 'product_sim_modal_combo_option_dual_sim',
    defaultMessage: 'Dual-SIM',
  },
  option5Desc: {
    id: 'product_sim_modal_combo_option_dual_sim_desc',
    defaultMessage: '2 physical slots',
  },
  option6: {
    id: 'product_sim_modal_combo_option_dual_sim_esim',
    defaultMessage: 'Dual-SIM + eSIM',
  },
  option6Desc: {
    id: 'product_sim_modal_combo_option_dual_sim_esim_desc',
    defaultMessage: '2 physical + 1 virtual slot',
  },
  option7: {
    id: 'product_sim_modal_combo_option_hybrid',
    defaultMessage: 'Hybrid Dual SIM',
  },
  option7Desc: {
    id: 'product_sim_modal_combo_option_hybrid_desc',
    defaultMessage: '2 physical slots',
  },

  infoBlockTitle: {
    id: 'product_sim_modal_info_title',
    defaultMessage: 'If I want to switch to an eSIM, what should I do?',
  },
  infoBlockText1: {
    id: 'product_sim_modal_info_text_1',
    defaultMessage:
      'If you want to switch, make sure your carrier supports eSIM. If it does, you just have to follow your phone’s instructions when configuring it.',
  },
  infoBlockText2: {
    id: 'product_sim_modal_info_text_2',
    defaultMessage:
      'If you don’t have an eSIM yet, most carriers will support a transfer to an eSIM.',
  },
  infoBlockReadMore: {
    id: 'product_sim_modal_info_read_more',
    defaultMessage: 'Read more',
  },

  openerPickers: {
    id: 'product_sim_modal_opener_pickers',
    defaultMessage: 'Which SIM to get?',
  },
  openerCustomizationFunnel: {
    id: 'product_sim_modal_opener_customization_funnel',
    defaultMessage:
      'A SIM allows you to activate a cellular plan from your carrier and holds information like your phone number. ',
  },
}
