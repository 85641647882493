<template>
  <div>
    <div class="mb-24 flex items-center gap-x-8">
      <h3 class="heading-3 w-1/2">
        {{ i18n(translations.section1Item1Title) }}
      </h3>
      <h3 class="heading-3 w-1/2">
        {{ i18n(translations.section1Item2Title) }}
      </h3>
    </div>

    <div
      class="text-static-default-hi body-1 relative grid grid-cols-2 gap-x-8"
      :class="$style.gridTemplateArea"
    >
      <IconReassuranceCertified
        alt=""
        class="absolute left-1/4 top-[-12px] ml-[-16px]"
        size="24"
      />
      <IconDiamond
        alt=""
        class="absolute right-1/4 top-[-12px] mr-[-16px]"
        size="24"
      />

      <p
        class="bg-static-default-mid rounded-t-md flex items-center justify-center p-16 pt-24"
        :class="$style.gridAreaCurrentText1"
      >
        {{ i18n(translations.section1Item1Paragraph1) }}
      </p>
      <p
        class="bg-static-default-mid flex items-center justify-center p-16"
        :class="$style.gridAreaCurrentText2"
      >
        {{ i18n(translations.section1Item1Paragraph2) }}
      </p>
      <p
        class="bg-static-default-mid rounded-b-md flex items-center justify-center p-16 pb-24"
        :class="$style.gridAreaCurrentText3"
      >
        {{ i18n(translations.section1Item1Paragraph3) }}
      </p>

      <p
        class="bg-static-default-mid rounded-t-md flex items-center justify-center p-16 pt-24"
        :class="$style.gridAreaNewText1"
      >
        {{ i18n(translations.section1Item2Paragraph1) }}
      </p>
      <p
        class="bg-static-default-mid flex items-center justify-center p-16"
        :class="$style.gridAreaNewText2"
      >
        {{ i18n(translations.section1Item2Paragraph2, { brand }) }}
      </p>
      <p
        class="bg-static-default-mid rounded-b-md flex items-center justify-center p-16 pb-24"
        :class="$style.gridAreaNewText3"
      >
        {{ i18n(translations.section1Item2Paragraph3) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconDiamond } from '@ds/icons/IconDiamond'
import { IconReassuranceCertified } from '@ds/icons/IconReassuranceCertified'

import translations from './ComparisonArea.translations'

const i18n = useI18n()

defineProps<{
  brand: string
}>()
</script>

<style module>
.gridTemplateArea {
  grid-template-areas:
    'current-text1 new-text1'
    'current-text2 new-text2'
    'current-text3 new-text3'
    'current-text4 new-text4';
}
.gridAreaCurrentText1 {
  grid-area: current-text1;
}
.gridAreaCurrentText2 {
  grid-area: current-text2;
}
.gridAreaCurrentText3 {
  grid-area: current-text3;
}

.gridAreaCurrentText4 {
  grid-area: current-text4;
}
.gridAreaNewText1 {
  grid-area: new-text1;
}
.gridAreaNewText2 {
  grid-area: new-text2;
}
.gridAreaNewText3 {
  grid-area: new-text3;
}

.gridAreaNewText4 {
  grid-area: new-text4;
}
</style>
