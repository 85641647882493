<template>
  <ReassuranceItem v-if="shouldDisplayCarriers">
    <template #icon>
      <IconLock v-if="isSimLocked" />
      <IconLockUnlocked v-if="!isSimLocked" />
    </template>
    <template #content>
      <div v-if="isSimLocked" class="w-full">
        <button
          class="body-2 flex w-full items-center justify-between text-left"
          @click="deployCarriersCompatibilityModal"
        >
          <div class="flex w-full justify-between">
            <span>
              {{ i18n(translations.labelLock) }}
            </span>

            <div class="ml-8 flex flex-wrap">
              <div
                v-for="carrier in carriers"
                :key="carrier.name"
                class="border-static-default-low rounded-xs mb-2 ml-2 flex h-24 h-[24px] border p-4"
              >
                <RevIllustration
                  v-if="carrier.logo"
                  :alt="carrier.name"
                  class="h-[24px] w-auto"
                  :height="24"
                  :src="
                    carrier.logo.includes('http')
                      ? carrier.logo
                      : toPublicPath(carrier.logo)
                  "
                  :width="60"
                />
                <span v-else>{{ carrier.name }}</span>
              </div>
            </div>
          </div>
          <IconChevronRight />
        </button>

        <CarriersCompatibilityModal type="locked" />
      </div>

      <div v-if="!isSimLocked" class="w-full">
        <button
          class="flex w-full items-center justify-between text-left"
          @click="deployCarriersCompatibilityModal"
        >
          <span class="body-2">
            {{ i18n(translations.labelUnlock) }}
          </span>
          <IconChevronRight />
        </button>
        <CarriersCompatibilityModal type="unlocked" />
      </div>
    </template>
  </ReassuranceItem>
</template>

<script lang="ts" setup>
import { toPublicPath } from '#imports'
import { computed } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconLock } from '@ds/icons/IconLock'
import { IconLockUnlocked } from '@ds/icons/IconLockUnlocked'

import { MODAL_NAMES } from '~/scopes/product/constants'

import CarriersCompatibilityModal from '../CarriersCompatibilityModal/CarriersCompatibilityModal.vue'
import ReassuranceItem from '../ReassuranceItem/ReassuranceItem.vue'

import translations from './ItemCarriers.translations'

const props = defineProps<{
  carriers: productAPI.GetProductResponse['compatibleCarriers']
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const shouldDisplayCarriers = computed(() => {
  return !isEmpty(props.carriers)
})

const isSimLocked = computed(() => {
  return props.carriers.some((carrier) => carrier.isSimLocked)
})

function deployCarriersCompatibilityModal() {
  trackClick({
    zone: 'carrier_compatibility',
    name: 'carrier_info',
  })
  openModal(MODAL_NAMES.CARRIERS_COMPATIBILITY)
}
</script>
