export default {
  errorTitle: {
    id: 'product_modal_api_error_title',
    defaultMessage: 'There seems to be a hitch!',
  },
  errorSubTitle: {
    id: 'product_modal_api_error_subtitle',
    defaultMessage: 'Refresh the page or try again later.',
  },
}
