import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  visibleByVerizonExclusivePlan: {
    id: 'visible_by_verizon_chip_exclusive_plan',
    defaultMessage: 'Exclusive: Visible by Verizon plan',
  },
  logo: {
    id: 'visible_by_verizon_tiny_logo',
    defaultMessage: 'Visible by verizon tiny logo',
  },
} as Record<string, I18nDefinition>
