<template>
  <div>
    <RevModal :name="name" :title="title" variant="panel" @open="onOpen">
      <template #trigger="{ open }">
        <slot v-bind="{ open }" name="trigger" />
      </template>
      <template #body>
        <div v-if="isSuccess">
          <slot name="content" />
        </div>

        <div
          v-if="isLoading"
          class="flex h-full w-full items-center justify-center"
        >
          <RevSpinner class="h-24 w-24" />
        </div>

        <div v-if="isError">
          <h3 class="heading-3 mt-32">
            {{ i18n(translations.errorTitle) }}
          </h3>
          <p class="body-1 mt-20">
            {{ i18n(translations.errorSubTitle) }}
          </p>
          <RevIllustration
            alt="error"
            class="mx-auto my-0 mt-32 block w-[10.3125rem]"
            :height="130"
            src="/img/product/ErrorModal.svg"
            :width="165"
          />
        </div>
      </template>
    </RevModal>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'
import { RevSpinner } from '@ds/components/Spinner'

import { STATUS } from './AsyncModal.constant'
import translations from './AsyncModal.translations'

const props = defineProps<{
  name: string
  title: string
  fetch: () => void
}>()

const i18n = useI18n()

const status = ref(STATUS.LOADING)
const isError = computed(() => status.value === STATUS.ERROR)
const isSuccess = computed(() => status.value === STATUS.SUCCESS)
const isLoading = computed(() => status.value === STATUS.LOADING)

async function onOpen() {
  status.value = STATUS.LOADING

  try {
    await props.fetch()
    status.value = STATUS.SUCCESS
  } catch {
    status.value = STATUS.ERROR
  }
}
</script>
