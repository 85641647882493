import { useRoute, useRuntimeConfig } from '#imports'
import { computed, ref, watch } from 'vue'

import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useRequests } from './useRequests'

function useFetchHighlightedReview() {
  const route = useRoute()

  const requests = useRequests()

  const source = 'solved_claim'
  const minRate = 4

  if (!requests || !('getReviews' in requests))
    return { data: ref(), error: ref(), pending: ref(false) }

  return useHttpFetch(requests.getReviews, {
    pathParams: route.params,
    queryParams: {
      source,
      global_rate__gte: minRate,
    },
    server: false,
    transform: ({ results }) => results[0],
  })
}

export function useHighlightedReview() {
  const route = useRoute()
  const logger = useLogger()
  const { KILL_REVIEW_HANDLED_WITH_CARE } = useRuntimeConfig().public

  if (KILL_REVIEW_HANDLED_WITH_CARE) {
    return {
      highlightedReview: ref(),
      isFetchHighlightedReviewPending: ref(false),
    }
  }

  const { data, error, pending } = useFetchHighlightedReview()

  watch(
    error,
    () => {
      if (error.value instanceof Error) {
        logger.error(`[REVIEWS][${route.name?.toString()}]`, {
          error: error.value,
        })
      }
    },
    { immediate: true },
  )

  const highlightedReview = computed(() =>
    route.query.global_rate__gte || route.query.global_rate__lte
      ? undefined
      : data.value,
  )
  const isFetchHighlightedReviewPending = computed(() =>
    route.query.global_rate__gte || route.query.global_rate__lte
      ? false
      : pending.value,
  )

  return {
    highlightedReview,
    isFetchHighlightedReviewPending,
  }
}
