<template>
  <div class="flex flex-row items-center">
    <div
      class="bg-static-info-mid rounded-sm mr-12 flex h-36 w-36 shrink-0 items-center justify-center"
    >
      <slot name="icon" />
    </div>
    <slot name="content" />
  </div>
</template>
