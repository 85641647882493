<template>
  <div>
    <div class="my-16 flex flex-row items-center">
      <div class="grow">
        <Price
          v-if="product && currentGrade"
          :offer="currentGrade"
          :price="price"
          :product="product"
        />
      </div>

      <RevButton
        data-id="product-page-buy-button-mobile"
        variant="primary"
        @click="onAddToCart"
      >
        {{ i18n(translations.addToCart) }}
      </RevButton>
    </div>

    <RevDivider class="my-16" />
  </div>
</template>

<script lang="ts" setup>
import { type Price as PriceType, productAPI } from '@backmarket/http-api'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDivider } from '@ds/components/Divider'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import Price from '../Price/Price.vue'

import translations from './TitleDesktop.translations'

const props = withDefaults(
  defineProps<{
    currentGrade?: productAPI.GetBestOffersResponse[number] | null
    product: productAPI.GetProductResponse
    price: PriceType
    showEcoBlock?: boolean
    rating?: Rating | null
    tracking: ReturnType<typeof useProductTracking>
    swapStatus?: string
  }>(),
  {
    currentGrade: null,
    showEcoBlock: false,
    rating: null,
    swapStatus: '',
  },
)

const { addToCart } = useAddToCart()
const i18n = useI18n()

function onAddToCart() {
  if (props.currentGrade) {
    addToCart({
      listingId: props.currentGrade.id,
      listingPublicId: props.currentGrade.publicId,
      tracking: {
        ...props.tracking.product.value,
        list: 'product_page',
      },
      swapStatus: props.swapStatus,
      partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
    })
  }
}
</script>
