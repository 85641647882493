<template>
  <div class="mb-32">
    <RevList :has-external-borders="false">
      <RevListItemInteractive @click="deployTechSpecModal">
        <template #label>
          {{ i18n(translations.techSpec) }}
        </template>
        <template #suffix>
          <IconChevronRight />
        </template>
      </RevListItemInteractive>
      <RevListItemInteractive @click="deployPerksModal">
        <template #label>
          {{ i18n(translations.warranty) }}
        </template>
        <template #suffix>
          <IconChevronRight />
        </template>
      </RevListItemInteractive>
      <RevListItemInteractive @click="deployFaqModal">
        <template #label>
          {{ i18n(translations.frequentlyAskedQuestions) }}
        </template>
        <template #suffix>
          <IconChevronRight />
        </template>
      </RevListItemInteractive>
    </RevList>
    <TechSpecModal :technical-specifications="technicalSpecifications" />
    <FaqModal :listing-id="listingId" />
    <PerksModal :listing-id="listingId" />
  </div>
</template>

<script lang="ts" setup>
import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './AdditionalInfo.translations'
import FaqModal from './components/FaqModal/FaqModal.vue'
import PerksModal from './components/PerksModal/PerksModal.vue'
import TechSpecModal from './components/TechSpecModal/TechSpecModal.vue'

defineProps<{
  listingId: number
  technicalSpecifications: productAPI.GetTechnicalSpecificationsResponse
}>()

const i18n = useI18n()

function deployTechSpecModal() {
  openModal(MODAL_NAMES.TECH_SPECS)
}

function deployFaqModal() {
  openModal(MODAL_NAMES.FAQ)
}

function deployPerksModal() {
  openModal(MODAL_NAMES.PERKS)
}
</script>
