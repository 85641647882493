<template>
  <div class="flex justify-center">
    <RevEditorialCard
      alt=""
      class="my-48"
      :content="i18n(translations.guideText)"
      :cta="i18n(translations.guideMore)"
      image="/img/product/carriers-modal-guide.png"
      :title="i18n(translations.guideTitle)"
      :title-kicker="i18n(translations.guideCategory)"
      title-tag="h2"
      :to="to"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevEditorialCard } from '@ds/components/EditorialCard'

import { CMS } from '~/scopes/cms/routes-names'

import translations from './CarriersCompatibilityModal.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const to = resolveLocalizedRoute({
  name: CMS.ARTICLE,
  params: {
    pageName: 'how-to-activate-my-phone',
    category: 'events',
  },
})
</script>
