<template>
  <div
    class="bg-overlap-default-low shadow-short rounded-lg flex flex-col md:flex-row"
  >
    <div class="order-2 md:order-1 md:flex md:items-center">
      <div class="p-24 pt-32 md:p-56">
        <h2 class="heading-1 mb-20">
          {{ i18n(translations.introductionTitle) }}
        </h2>
        <p class="body-1 mb-56 md:mb-32">
          <FormattedMessage :definition="translations.introductionDescription">
            <template #link>
              <RevLink class="inline-block" target="_blank" :to="GOOGLE_URL">
                {{ i18n(translations.introductionLinkText) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </p>
        <RevButton
          full-width="responsive"
          target="_blank"
          :to="ctaRoute"
          variant="primary"
          @click="trackInteraction"
        >
          {{ i18n(translations.introductionCta) }}
        </RevButton>
      </div>
    </div>
    <div class="order-1 md:order-2">
      <img
        alt=""
        class="w-full md:hidden"
        :height="184"
        :src="toPublicPath('/img/product/about-us/mobile.svg')"
        :width="327"
      />
      <img
        alt=""
        class="rounded-lg hidden h-full max-w-none md:block"
        :height="300"
        :src="toPublicPath('/img/product/about-us/desktop.svg')"
        :width="448"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toPublicPath, useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevLink } from '@ds/components/Link'

import { ABOUT_US as ABOUT_US_ROUTE } from '~/scopes/about-us/routes.constants'

import { GOOGLE_URL } from './AboutUs.constants'
import translations from './AboutUs.translations'

const { trackClick } = useTracking()

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const ctaRoute = computed(() => {
  return resolveLocalizedRoute({ name: ABOUT_US_ROUTE })
})

function trackInteraction() {
  trackClick({ zone: 'detail', name: 'get_to_know_us' })
}
</script>
