<template>
  <StepLayout
    :is-focused="isFocused"
    :tracking="{
      trackingTags: {
        tagDisplayed: 'no_tag',
        tagSelected: false,
      },
      trackingId: props.step.trackingId,
      trackingModel: props.model,
    }"
  >
    <template #left>
      <RevIllustration
        v-if="step.desktopIllustration"
        alt=""
        class="block rounded-[20px] md:rounded-[32px]"
        :height="632"
        :src="step.desktopIllustration"
        :width="776"
      />
    </template>
    <template #right>
      <div class="flex flex-col gap-16">
        <h3 class="heading-1">
          <FormattedMessage :definition="translations.titleStep">
            <template #emphasis>
              <em>
                {{ i18n(translations.titleStepEmphasis) }}
              </em>
            </template>
          </FormattedMessage>
        </h3>
        <CardGuidance v-if="step.guidance" v-bind="step.guidance" />
        <ul class="list-none">
          <li class="mb-12">
            <LargePicker
              v-if="noOptionStep"
              :index="0"
              :label="noOptionLabel"
              :prefix="noOptionPrefix"
              :selected="pickerSelected"
              @click="onClickNoPicker"
            />
          </li>
          <li>
            <LargePicker
              v-if="swapOptionStep?.selected"
              :index="1"
              :label="swapOptionLabel"
              :prefix="swapOptionPrefix"
              :selected="!pickerSelected"
              :sub-label="swapOptionSubLabel"
              :tag="swapOptionTag"
              @click="onClickNoPicker"
            />
            <div v-else>
              <RevInputSelectSearchable
                id="swap-select"
                :clear-button-aria-label="
                  i18n(translations.clearButtonAriaLabel)
                "
                :defaultOption="defaultOption"
                :hideCheckboxes="true"
                :label="i18n(translations.swapSelectPlaceholder)"
                listbox-placement="bottom"
                :model-value="selectModel"
                :no-options-message="
                  i18n(translations.swapSelectNoOptionsMessage)
                "
                :options="filteredOptions"
                @focus="handleFocus"
                @text-change="handleTextChange"
                @update:model-value="handleModelValueUpdate as unknown"
              />
              <span class="text-static-default-low body-2">
                {{ i18n(translations.swapSelectExample) }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </StepLayout>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { cartAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInputSelectSearchable } from '@ds/components/InputSelectSearchable'
import { openModal } from '@ds/components/ModalBase'
import { type Variants as TagVariants } from '@ds/components/Tag'

import { MODAL_NAMES } from '~/scopes/buyback/constants'
import { useSwapStore } from '~/scopes/buyback/swap/stores/swap'
import CardGuidance from '~/scopes/product/components/CustomizationFunnel/components/CardGuidance/CardGuidance.vue'
import LargePicker from '~/scopes/product/components/CustomizationFunnel/components/LargePicker/LargePicker.vue'
import StepLayout from '~/scopes/product/components/CustomizationFunnel/components/StepLayout/StepLayout.vue'
import type { Step } from '~/scopes/product/components/CustomizationFunnel/utils/types'

import { dropdownData } from '../../dropdown'

import translations from './StepSwap.translations'

type OptionItem = {
  id: number | string
  label: string
  value: string
}

const props = defineProps<{
  step: Partial<Step>
  swapListings?: Array<number>
  isFocused: boolean
  model: string
}>()

const i18n = useI18n()
const { market } = useMarketplace()
const {
  fetchSwapEstimations,
  declineEstimation,
  setInitialPayload,
  resetInitialPayload,
} = useSwapStore()
const { trackClick, trackFormSubmit } = useTracking()

const models = dropdownData[market.marketplace]

const options: OptionItem[] = models.map((model) => {
  return {
    label: i18n(translations.swapSelectLabelOption, {
      model: model.value.model,
      price: i18n.price(model.price.amount),
    }),
    id: model.id,
    value: model.id,
  }
})

const defaultOption = {
  id: 'default',
  label: i18n(translations.swapSelectLabelOptionDefault),
  value: 'default',
}

const selectModel = ref<OptionItem | null>(null)
const textInput = ref('')
const pickerSelected = ref(false)

watch(
  () => props.isFocused,
  (isFocused) => {
    if (!isFocused) {
      const focusedElement = document.activeElement as HTMLElement
      focusedElement?.blur()
    }
  },
)

const filteredOptions = computed(() => {
  if (textInput.value === '') {
    return options
  }

  return options.filter((option) => {
    return option.label
      .toLocaleLowerCase()
      .includes(textInput.value.toLocaleLowerCase())
  })
})

const noOptionStep = computed(() => {
  return props.step.options?.[0] ? props.step.options?.[0] : null
})

const swapOptionStep = computed(() => {
  return props.step.options?.[1] ? props.step.options?.[1] : null
})

const noOptionLabel = computed(() => {
  if (noOptionStep.value) {
    return typeof noOptionStep.value?.label === 'string'
      ? noOptionStep.value.label
      : i18n(noOptionStep.value.label)
  }

  return ''
})

const noOptionPrefix = computed(() => {
  return noOptionStep.value?.style?.prefix
    ? noOptionStep.value.style?.prefix
    : undefined
})

const swapOptionLabel = computed(() => {
  if (swapOptionStep.value) {
    return typeof swapOptionStep.value?.label === 'string'
      ? swapOptionStep.value.label
      : i18n(swapOptionStep.value.label)
  }

  return ''
})

const swapOptionSubLabel = computed(() => {
  if (swapOptionStep.value && swapOptionStep.value.subLabel) {
    return typeof swapOptionStep.value?.subLabel === 'string'
      ? swapOptionStep.value.subLabel
      : i18n(swapOptionStep.value.subLabel)
  }

  return ''
})

const swapOptionPrefix = computed(() => {
  return swapOptionStep.value?.style?.prefix
    ? swapOptionStep.value.style?.prefix
    : undefined
})

const swapOptionTag = computed(() => {
  return swapOptionStep.value?.tag
    ? {
        label:
          typeof swapOptionStep.value.tag === 'object'
            ? i18n(swapOptionStep.value.tag)
            : swapOptionStep.value.tag,
        variant: 'primary' as TagVariants,
      }
    : undefined
})

function handleTextChange(value: string) {
  textInput.value = value
}
function handleModelValueUpdate(option: OptionItem | null) {
  selectModel.value = option
  pickerSelected.value = false

  if (option) {
    if (option.value === defaultOption.value) {
      resetInitialPayload()

      openModal(MODAL_NAMES.SWAP_MIX_AND_MATCH)

      trackFormSubmit({
        zone: 'tradein_searchbar',
        name: option.value,
        payload: {
          search_type: 'no result',
        },
      })

      return
    }

    const initialValue =
      models.find((model) => model.id === option.id)?.value || null
    if (initialValue) {
      setInitialPayload(initialValue)

      openModal(MODAL_NAMES.SWAP_MIX_AND_MATCH)
      if (textInput.value === '') {
        trackFormSubmit({
          zone: 'tradein_searchbar',
          name: option.value,
          payload: {
            search_type: 'popularSearches',
          },
        })
      } else {
        trackFormSubmit({
          zone: 'tradein_searchbar',
          name: option.value,
          payload: {
            search_type: 'suggestions',
          },
        })
      }
    }
  }
}

async function onClickNoPicker() {
  resetInitialPayload()
  pickerSelected.value = true
  selectModel.value = null

  await $httpFetch(cartAPI.deleteSwap)
  declineEstimation()

  if (props.swapListings) {
    await fetchSwapEstimations(props.swapListings)
  }

  trackClick({
    zone: 'swap',
    name: 'no',
  })
}

function handleFocus() {
  trackClick({
    zone: 'swap',
    name: 'yes',
  })
}
</script>
