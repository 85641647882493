import { createHttpEndpoint } from '../utils'

import type { Favorite } from './models/favorite'

/**
 * Create favorite
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/my-favorites-service-api/definition#tag/Favorites/operation/create-favorite}
 */
export const createFavoriteForUser = createHttpEndpoint<Favorite>({
  method: 'POST',
  operationId: 'createFavoriteForUser',
  path: '/my-favorites/favorites',
})

/**
 * Delete favorite
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/my-favorites-service-api/definition#tag/Favorites/operation/delete-favorite}
 */
export const deleteUserFavorite = createHttpEndpoint({
  method: 'DELETE',
  operationId: 'deleteUserFavorite',
  path: '/my-favorites/favorites/:favoriteId',
})

/**
 * Get favorite From product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/my-favorites-service-api/definition#tag/Favorites/operation/get-favorite}
 */
export const getFavoritesFromProduct = createHttpEndpoint<Array<Favorite>>({
  method: 'GET',
  operationId: 'getFavoritesFromProduct',
  path: '/my-favorites/favorites/products/:productUuid',
})
