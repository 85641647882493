<template>
  <div v-if="shouldDisplayGallery">
    <div class="flex justify-center" data-test="carousel">
      <RevCarousel
        :alternative-button-label="i18n(translations.alternativeController)"
        :alternative-next="i18n(translations.alternativeNext)"
        :alternative-previous="i18n(translations.alternativePrevious)"
        class="mt-12 md:mt-32 md:grow"
        current-index-id="gallery-carousel"
        :thumbnails="thumbnails"
        :tracking="{ name: 'product' }"
        @navigation-click="() => trackCarousel('thumbnail_carousel')"
        @next-button-click="() => trackCarousel('right_arrow')"
        @prev-button-click="() => trackCarousel('left_arrow')"
        @swipe="() => trackCarousel('swipe')"
      >
        <RevIllustration
          v-for="(image, index) in carouselImages"
          :key="image.src"
          :alt="image.alt"
          class="block !h-[66.7vw] max-h-full w-auto cursor-pointer md:!h-auto lg:w-[29.125rem]"
          :height="976"
          :loading="shouldLoadEagerly(index)"
          sizes="(max-width: 768px) 100vw, 466px"
          :src="image.src"
          :width="976"
          @click="handleCarouselClick"
        />
      </RevCarousel>
    </div>
    <RevMediaViewer
      v-if="allowMediaViewer"
      :alternative-button-label="i18n(translations.alternativeController)"
      :alternative-next="i18n(translations.alternativeNext)"
      :alternative-previous="i18n(translations.alternativePrevious)"
      :close-button-label="i18n(translations.alternativeClose)"
      current-index-id="gallery-media-viewer"
      data-test="product-page-media-viewer"
      :name="MODAL_NAMES.MEDIA_VIEWER"
      :thumbnails="thumbnails"
    >
      <template #default="{ imgClassNames = '' } = {}">
        <RevIllustration
          v-for="(image, index) in carouselImages"
          :key="image.alt"
          :alt="image.alt"
          :class="imgClassNames"
          :height="1200"
          :loading="shouldLoadEagerly(index)"
          :src="image.src"
          :width="1200"
        />
      </template>
    </RevMediaViewer>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'
import { RevMediaViewer } from '@ds/components/MediaViewer'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './Gallery.translations'

const props = withDefaults(
  defineProps<{
    images: productAPI.GetProductResponse['images']
    allowMediaViewer?: boolean
    allowPartnersImages?: boolean
    trackingProductModel: string
    trackingZone: string
  }>(),
  {
    allowMediaViewer: true,
    allowPartnersImages: true,
  },
)

const i18n = useI18n()
const { trackClick } = useTracking()

const carouselImages = computed(() => {
  return props.images
    .filter((image) => props.allowPartnersImages || image.source !== 'PARTNER')
    .map((image) => ({
      src: image.url,
      alt: image.alt,
    }))
})

const thumbnails = computed(() => {
  return carouselImages.value.map((image) => ({
    ...image,
    width: 100,
    height: 100,
  }))
})

const shouldDisplayGallery = computed(() => props.images.length > 0)

function shouldLoadEagerly(index: number) {
  return index === 0 ? 'eager' : 'lazy'
}

function trackCarousel(name: string): void {
  trackClick({
    name,
    zone: props.trackingZone,
    product_model: props.trackingProductModel,
  })
}

function handleCarouselClick(): void {
  if (props.allowMediaViewer) {
    trackCarousel('photo_carousel')
    openModal(MODAL_NAMES.MEDIA_VIEWER)
  }
}
</script>
