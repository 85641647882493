import type { productAPI } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import {
  type PickersResponseItem,
  hasItemExtraData,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product'

/**
 * We would like to do an A/B/C test:
 *   A: 2 current offers: 9.99 and 19.99 (withoutB&YOU1499Offer)
 *   B: 3 offers (noFilter/noVariation)
 *   C: 2 offers: 9.99 and 14.99 (withoutB&YOU1999Offer)
 *
 * It has been decided that we do this filtering frontend side
 * Decision here: https://backmarket.atlassian.net/wiki/spaces/2C/pages/3963879552/New+Bouygues+offer+A+B+C+test+Tech+decision
 *
 * More context: https://backmarket.atlassian.net/browse/B2CS-552
 * */
function isOfferInVariation(
  offer:
    | MobilePlanOffer
    | productAPI.GetProductResponse['includedServiceOffers']['mobilePlans'][number],
  variation: string,
) {
  if (
    variation === 'withoutB&YOU1999Offer' &&
    offer.name === 'B&YOU_300GO_1999'
  )
    return false

  if (
    variation === 'withoutB&YOU1499Offer' &&
    offer.name === 'B&YOU_150GO_1499'
  )
    return false

  return true
}

export function getFilteredOffersFromVariation(
  offers:
    | MobilePlanOffer[]
    | productAPI.GetProductResponse['includedServiceOffers']['mobilePlans'],
  variation: string,
) {
  return offers.filter((offer) => isOfferInVariation(offer, variation))
}

export function getFilteredItemsFromVariation(
  items: PickersResponseItem[],
  variation: string,
) {
  return items.filter((item) => {
    if (!hasItemExtraData(item)) return true

    return isOfferInVariation(item.extraData, variation)
  })
}
