export default {
  header: {
    id: 'product_page_copy_levy_modal_header',
    defaultMessage: '',
  },
  title: {
    id: 'product_page_copy_levy_modal_title',
    defaultMessage: '',
  },
  paragraph1: {
    id: 'product_page_copy_levy_modal_paragraph_1',
    defaultMessage: '',
  },
  paragraph2: {
    id: 'product_page_copy_levy_modal_paragraph_2',
    defaultMessage: '',
  },
}
