<template>
  <div
    ref="root"
    class="bg-static-default-low relative aspect-[2/3] w-full overflow-hidden md:w-auto"
    :class="$style.storiesContainer"
  >
    <div class="absolute left-0 right-0 top-0 z-[1]">
      <slot name="breadcrumb" />
      <RevContainer>
        <ProgressBar
          class="md:hidden"
          :currentIndex="currentIndex"
          :items="items"
        />
      </RevContainer>
    </div>

    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
    <div
      ref="overlay"
      class="flex h-full justify-center"
      :class="[
        currentItem.type === 'image' && currentItem.source !== 'PARTNER'
          ? imagesPositions[currentIndex]
          : '',
        currentItem.type !== 'image' ? 'items-center' : '',
      ]"
      @click="onClick"
    >
      <RevIllustration
        v-if="currentItem.type === 'image' && !isError"
        :key="currentIndex"
        :alt="currentItem.alt"
        :class="
          currentItem.source !== 'PARTNER'
            ? [$style[`animation${currentIndex}`], imagesSizes[currentIndex]]
            : ''
        "
        :height="1200"
        :loading="shouldLoadEagerly"
        :src="currentItem.url"
        :width="1200"
        @error="onImageError"
      />
      <video
        v-if="currentItem.type === 'video'"
        :key="currentIndex"
        aria-hidden="true"
        autoplay
        muted
        playsinline
      >
        <source :src="currentItem.url" type="video/mp4" @error="onVideoError" />
      </video>
      <div
        v-if="isError"
        class="text-static-default-low absolute bottom-0 left-0 right-0 top-[30%] flex flex-col gap-8 px-44 text-center md:top-0 md:justify-center"
      >
        <IconImage class="relative left-1/2 -translate-x-1/2" size="48" />
        <span class="body-1-bold">
          {{ i18n(translations.loadingErrorTitle) }}
        </span>
        <p class="body-2 mt-8">
          {{ i18n(translations.loadingErrorDescription) }}
        </p>
      </div>
    </div>

    <div class="absolute bottom-[-4px] left-0 right-0 z-[1]">
      <p
        v-if="currentItem.type === 'video'"
        class="text-static-default-hi mood-inverse caption-bold mb-8 max-w-[55%] pl-24 md:mb-16 md:max-w-none md:pl-20"
        :class="$style.disclaimerShadow"
      >
        {{ i18n(translations.videoDisclaimer) }}
      </p>

      <div :class="$style.gradientTitle">
        <slot name="title" />
      </div>
      <div class="mx-16 mb-24 hidden md:block">
        <ProgressBar :currentIndex="currentIndex" :items="items" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { IconImage } from '@ds/icons/IconImage'
import { useIntersectionObserver } from '@vueuse/core'

import translations from './ImmersiveStories.translations'
import { getStoriesItems } from './ImmersiveStories.utils'
import ProgressBar from './components/ProgressBar/ProgressBar.vue'

const props = defineProps<{
  images: productAPI.GetProductResponse['images']
  trackingCategory: string
  trackingModel: string
}>()

const root = ref<HTMLElement | null>()
const { trackProductBlockImpression } = useTracking()
const i18n = useI18n()
const logger = useLogger()
const currentIndex = ref(0)
const isError = ref(false)
const timeout = ref<ReturnType<typeof setTimeout> | null>(null)
const overlay = ref<HTMLElement | null>(null)
const isVisible = ref(false)

const items = computed(() => getStoriesItems(props.images))

const imagesSizes = [
  '!h-[56%] w-fit max-w-none',
  '!h-[91%] w-fit max-w-none',
  '!h-[84%] w-fit max-w-none',
]
const imagesPositions = [
  'relative top-[13%] md:top-0 md:items-center',
  'relative top-[1.5%] md:top-0 items-end md:items-center',
  'items-end md:items-center',
]

const currentItem = computed(() => {
  return items.value[currentIndex.value]
})

const shouldLoadEagerly = computed(() => {
  return currentIndex.value === 0 ? 'eager' : 'lazy'
})

function trackImpression(autoImpression: boolean) {
  // Don't track automatic slide when the user isn't focused on the stories. It'll mess with the
  // data analysis and cost a log of money $$$
  if (autoImpression && !isVisible.value) {
    return
  }

  trackProductBlockImpression({
    block_name: 'content_carousel',
    position: currentIndex.value,
    product_model: props.trackingModel,
    product_category: props.trackingCategory,
    content_type: currentItem.value.type,
    auto_impression: autoImpression,
  })
}

function startTimer() {
  timeout.value = setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    next()
    trackImpression(true)
  }, currentItem.value.duration * 1000)
}

function resetTimer() {
  if (timeout.value) {
    clearTimeout(timeout.value)
    timeout.value = null
  }
}

function next() {
  if (currentIndex.value === items.value.length - 1) {
    currentIndex.value = 0
  } else {
    currentIndex.value += 1
  }

  resetTimer()
  startTimer()
  isError.value = false
}

function previous() {
  if (currentIndex.value === 0) {
    currentIndex.value = items.value.length - 1
  } else {
    currentIndex.value -= 1
  }

  resetTimer()
  startTimer()
  isError.value = false
}

function onClick(event: MouseEvent) {
  if (overlay.value) {
    const divider = overlay.value.offsetWidth / 10
    if (event.clientX - divider > divider) {
      next()
    } else {
      previous()
    }
  }
  trackImpression(false)
}

function onImageError() {
  logger.error('[PRODUCT][ImmersiveStories] image cannot be loaded')
  isError.value = true
}

function onVideoError() {
  logger.error('[PRODUCT][ImmersiveStories] video cannot be loaded')
  isError.value = true
}

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting
})

onUnmounted(() => {
  stop()
})

onMounted(() => {
  startTimer()
})

onBeforeUnmount(() => {
  resetTimer()
})
</script>

<style module>
/* Using custom styles here to be more precise about breakpoints */
.storiesContainer {
  @media (min-width: 768px) and (orientation: portrait) {
    height: 30vh;
  }
  @media (min-width: 768px) and (orientation: landscape) {
    height: 50vh;
  }
  @media (min-width: 1024px) {
    height: 70vh;
  }
}

.animation0 {
  animation: animation0 4.5s ease-out forwards;
}
@keyframes animation0 {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1.04);
  }
}

.animation1 {
  animation: animation1 4.5s ease-out forwards;
}
@keyframes animation1 {
  0% {
    transform: rotate(0) scale(1);
  }
  100% {
    transform: rotate(5deg) scale(1.05);
  }
}

.animation2 {
  animation: animation2 4.5s ease-out forwards;
}
@keyframes animation2 {
  0% {
    transform: rotate(0) scale(1);
  }
  100% {
    transform: rotate(-4deg) scale(1.06);
  }
}

.gradientTitle {
  @media (max-width: 767px) {
    background: linear-gradient(to top, white 30%, rgba(255, 255, 255, 0) 100%);
  }
}

.disclaimerShadow {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}
</style>
