<template>
  <CustomizationFunnel
    v-if="pickersResponse || !isPickersResponsePending"
    :grade="currentGrade"
    :pickers="pickersResponse?.pickers ?? []"
    :price="price"
    :product="product"
    :product-tracking="productTracking"
    :swap-listings="swapListings"
    :swap-offer="swapOffer"
    :swap-status="swapStatus"
  >
    <template #sticky-bar="{ title, currentStep, totalSteps }">
      <StickyBarTop
        :current-grade="currentGrade"
        :is-customization-funnel-enabled="true"
        :price="price"
        :product="product"
        :swap-status="swapStatus"
        :title="title"
        :tracking="productTracking"
        @hide="handleHideTopBar"
        @show="handleShowTopBar"
      >
        <template #progress-bar>
          <ProgressBar :current-step="currentStep" :total-steps="totalSteps" />
        </template>
      </StickyBarTop>

      <StickyBarTopSmall
        :is-customization-funnel-enabled="true"
        :title="title"
        @hide="handleHideTopBar"
        @show="handleShowTopBar"
      >
        <template #progress-bar>
          <ProgressBar :current-step="currentStep" :total-steps="totalSteps" />
        </template>
      </StickyBarTopSmall>
    </template>
    <template #details>
      <Details
        :current-grade="currentGrade"
        :has-swap="false"
        :product="product"
        :product-category="productCategory"
        :should-display-vat="false"
        :show-seller-block="false"
        :swap-offer="swapOffer"
        :swap-status="swapStatus"
        :technical-specifications="technicalSpecifications"
      />
    </template>
  </CustomizationFunnel>
</template>

<script lang="ts" setup>
import { type Price, productAPI } from '@backmarket/http-api'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

import { useProductTracking } from '../../composables/useProductTracking'
import CustomizationFunnel from '../CustomizationFunnel/CustomizationFunnel.vue'
import ProgressBar from '../CustomizationFunnel/components/ProgressBar/ProgressBar.vue'
import StickyBarTopSmall from '../CustomizationFunnel/components/StickyBarTopSmall/StickyBarTopSmall.vue'
import Details from '../Details/Details.vue'
import StickyBarTop from '../StickyBarTop/StickyBarTop.vue'

defineProps<{
  currentGrade: productAPI.GetBestOffersResponse[number]
  isPickersResponsePending: boolean
  newBattery?: productAPI.GetBestOffersResponse[number]['newBattery']
  pickersResponse: productAPI.GetPickersResponse | null
  price: Price
  product: productAPI.GetProductResponse
  productCategory: string
  swapListings?: Array<number>
  swapOffer?: Estimation
  swapStatus?: string
  productTracking: ReturnType<typeof useProductTracking>
  technicalSpecifications: productAPI.GetTechnicalSpecificationsResponse | null
}>()

const emit = defineEmits(['hide', 'show'])

function handleShowTopBar() {
  emit('show')
}
function handleHideTopBar() {
  emit('hide')
}
</script>
