<template>
  <RevModal
    :name="MODAL_NAMES.CARRIERS_COMPATIBILITY"
    :title="i18n(modalTitle)"
    variant="panel"
  >
    <template #body>
      <div class="pb-32">
        <div class="mb-32 text-center">
          <RevIllustration
            alt=""
            class="w-full"
            :height="240"
            src="/img/product/carriers-modal.svg"
            :width="327"
          />
        </div>

        <h2 class="heading-1 mb-16 text-center">
          {{ i18n(primaryTitle) }}
        </h2>

        <ContentUnlocked v-if="isUnlocked" />
        <ContentLocked v-if="isLocked" />

        <ContentInfo />

        <h2 class="heading-2 mb-8">
          {{ i18n(secondaryTitle) }}
        </h2>

        <ContentUnlocked v-if="isLocked" />
        <ContentLocked v-if="isUnlocked" />
      </div>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './CarriersCompatibilityModal.translations'
import ContentInfo from './ContentInfo.vue'
import ContentLocked from './ContentLocked.vue'
import ContentUnlocked from './ContentUnlocked.vue'

const props = defineProps<{
  type: 'locked' | 'unlocked'
}>()

const i18n = useI18n()

const isLocked = computed(() => {
  return props.type === 'locked'
})

const isUnlocked = computed(() => {
  return props.type === 'unlocked'
})

const modalTitle = computed(() => {
  return isUnlocked.value
    ? translations.titleUnlocked
    : translations.titleLocked
})

const primaryTitle = computed(() => {
  return isUnlocked.value
    ? translations.titleUnlockedFull
    : translations.titleLockedFull
})

const secondaryTitle = computed(() => {
  return isUnlocked.value
    ? translations.titleLocked
    : translations.titleUnlocked
})
</script>
