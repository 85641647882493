export default {
  section1Item1Title: {
    id: 'product_page_new_battery_modal_section1_item1_title',
    defaultMessage: 'With current battery',
  },
  section1Item1Paragraph1: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph1',
    defaultMessage: 'At least 85% battery capacity',
  },
  section1Item1Paragraph2: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph2',
    defaultMessage: 'Long lasting charges for normal use',
  },
  section1Item1Paragraph3: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph3',
    defaultMessage: 'Lowest environmental impact',
  },
  section1Item2Title: {
    id: 'product_page_new_battery_modal_section1_item2_title',
    defaultMessage: 'With new battery',
  },
  section1Item2Paragraph1: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph1',
    defaultMessage: '100% battery capacity',
  },
  section1Item2Paragraph2: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph2',
    defaultMessage: 'Longer lasting charges for heavy use',
  },
  section1Item2Paragraph3: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph3',
    defaultMessage: 'Low environmental impact',
  },
}
