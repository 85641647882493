import GradesModal from './components/GradesModal/GradesModal.vue'
import SimModal from './components/SimModal/SimModal.vue'

export const PICKERS_MODALS: Record<string, unknown> = {
  grades: GradesModal,
  dual_sim: SimModal,
}

// When a picker from this group is selected,
// it loads on battery selection
export const PICKER_GROUP_WITH_LOAD = 'grades'
