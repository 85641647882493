<template>
  <RevInfoBlock
    v-if="shouldDisplayIncompatibleSIMBlock"
    ref="root"
    :content="i18n(translations.description)"
    :icon="IconInfo"
    :title="i18n(translations.title)"
    variant="info"
  />
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconInfo } from '@ds/icons/IconInfo'
import { useIntersectionObserver } from '@vueuse/core'

import { type Step } from '../../utils/types'

import translations from './BouyguesIncompatibleSIM.translations'

const props = defineProps<{
  step: Step
  trackingModel: string
}>()

const i18n = useI18n()
const { trackProductBlockImpression } = useTracking()
const root = ref<HTMLElement | null>()

const shouldDisplayIncompatibleSIMBlock = computed(() => {
  return props.step.options.slice(1).every((o) => !o.available)
})

function trackImpression() {
  trackProductBlockImpression({
    block_name: 'Bouygues_offers_warning_esim',
    product_model: props.trackingModel || '',
  })
}

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackImpression()
  }
})

onUnmounted(() => {
  stop()
})
</script>
