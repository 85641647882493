<template>
  <div>
    <RevList :has-dividers="false" interactive>
      <RevListItemInteractive
        class="last:first:border-b-0"
        @click="clickHandler"
      >
        <template #label>
          <span class="heading-3">
            {{ i18n(translations.body, { totalPrice }) }}
          </span>
        </template>
        <template #suffix>
          <IconChevronRight />
        </template>
      </RevListItemInteractive>
    </RevList>

    <RevModal
      background-color="low"
      closable
      :name="MODAL_NAMES.BUNDLE"
      :title="i18n(translations.title)"
      variant="confirmation"
    >
      <template #body>
        <div class="text-action-default-hi">
          <div class="flex flex-col gap-16">
            <BundledProduct
              :description="mainProduct.description"
              :grade="mainProduct.grade.name"
              :horizontal="true"
              :image-src="mainProduct.image"
              :price="mainProduct.price"
              :price-new="mainProduct.priceNew"
              :title="mainProduct.title"
              :with-informations="true"
            />
            <div class="flex items-center justify-center gap-10">
              <div
                class="border-static-default-low w-24 border-t border-dashed"
              />
              <IconPlus size="medium" />
              <div
                class="border-static-default-low grow border-t border-dashed"
              />
            </div>
            <BundledProduct
              :description="recommendedProduct.description"
              :grade="recommendedProduct.grade.name"
              :horizontal="true"
              :image-src="recommendedProduct.image"
              :price="recommendedProduct.price"
              :price-new="recommendedProduct.priceNew"
              :title="recommendedProduct.title"
              :with-informations="true"
            />
          </div>

          <AddToCartBundleButton
            class="pb-4 pt-24"
            :main-product="mainProduct"
            :recommended-product="recommendedProduct"
            :total-price="totalPrice"
            variant="compact"
            @add-to-cart="emit('add-to-cart')"
          />
        </div>
      </template>
    </RevModal>
  </div>
</template>

<script setup lang="ts">
import AddToCartBundleButton from '@backmarket/nuxt-layer-recommendation/AddToCartBundleButton.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { RevModal } from '@ds/components/Modal'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconPlus } from '@ds/icons/IconPlus'

import { MODAL_NAMES } from '~/scopes/product/constants'

import BundledProduct from './BundledProduct.vue'
import translations from './Recommendation.translations'

const { mainProduct, recommendedProduct } = withDefaults(
  defineProps<{
    totalPrice?: string
    mainProduct: Product
    recommendedProduct: Product
  }>(),
  {
    totalPrice: '',
  },
)

const i18n = useI18n()

function clickHandler() {
  openModal(MODAL_NAMES.BUNDLE)
}

const emit = defineEmits(['add-to-cart'])
</script>
