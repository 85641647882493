<template>
  <div
    ref="root"
    class="md:flex md:min-h-[480px]"
    :class="[{ 'md:items-stretch': stretch, 'md:items-center': !stretch }]"
  >
    <div class="mr-56 hidden grow md:relative md:block">
      <div
        class="opacity-100 transition-opacity duration-500 ease-out motion-reduce:transition-none"
        :class="[slotClasses.left, { 'md:opacity-50': !isFocused }]"
      >
        <slot name="left" />
      </div>
    </div>

    <div class="md:w-[400px] md:shrink-0">
      <div
        class="opacity-100 transition-opacity duration-500 ease-out motion-reduce:transition-none"
        :class="[slotClasses.right, { 'md:opacity-50': !isFocused }]"
      >
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

const { trackProductBlockImpression } = useTracking()
const root = ref<HTMLElement | null>()

const THRESHOLDS: Record<string, number> = {
  default: 0.5,
  recap: 0.25,
}

const props = withDefaults(
  defineProps<{
    slotClasses?: { left: string; right: string }
    isFocused?: boolean
    tracking?: {
      trackingTags?: {
        tagDisplayed: string
        tagSelected: boolean
      }
      trackingId?: string
      trackingModel?: string
    }
    stretch?: boolean
  }>(),
  {
    slotClasses: () => ({
      left: '',
      right: '',
    }),
    isFocused: true,
    tracking: () => ({}),
    stretch: false,
  },
)

function trackImpression() {
  trackProductBlockImpression({
    product_price_tag_displayed:
      props.tracking.trackingTags?.tagDisplayed ?? 'no_tag',
    product_price_tag_selected: Boolean(
      props.tracking.trackingTags?.tagSelected,
    ),
    block_name: props.tracking.trackingId || '',
    product_model: props.tracking.trackingModel || '',
  })
}

const { stop } = useIntersectionObserver(
  root,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      trackImpression()
    }
  },
  {
    threshold:
      props.tracking.trackingId && props.tracking.trackingId in THRESHOLDS
        ? THRESHOLDS[props.tracking.trackingId]
        : THRESHOLDS.default,
  },
)

onUnmounted(() => {
  stop()
})
</script>
