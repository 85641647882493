<template>
  <StepLayout
    class="mb-56"
    :slot-classes="{
      left: 'left-0 top-[78px] flex justify-center md:sticky',
      right: 'md:pt-72',
    }"
    stretch
    :tracking="trackingStepLayout"
  >
    <template #left>
      <Gallery
        :allow-media-viewer="false"
        :allow-partners-images="false"
        class="mb-16"
        :images="images"
        :tracking-product-model="product.model"
        tracking-zone="pp_recap_carousel"
      />
    </template>

    <template #right>
      <h2 class="punchline mb-24 text-center md:text-left">
        {{ i18n(translations.resultTitle) }}
      </h2>

      <div class="mb-24 flex justify-center md:hidden">
        <RevIllustration
          v-if="product.images?.[0]"
          :alt="product.images[0].alt"
          class="mb-16"
          :height="224"
          :src="product.images[0].url"
          :width="224"
        />
      </div>

      <div class="relative">
        <RevIllustration
          alt=""
          class="absolute -right-24 top-[-110px] w-[140px] scale-100 opacity-100 transition-all duration-700 ease-out motion-reduce:transition-none md:right-0 md:top-[-115px]"
          :class="{
            '-translate-y-full scale-90 opacity-0 md:-translate-y-1/2':
              !isFocused,
          }"
          :height="200"
          src="/img/product/funnel/results.svg"
          :width="200"
        />

        <div
          class="bg-surface-default-low border-static-default-hi rounded-sm relative border p-12 pt-24"
        >
          <h3 class="heading-3">
            {{ product.title }}

            <div
              v-if="selectedColorCode"
              aria-hidden
              class="rounded-full ml-8 inline-block size-[12px]"
              :style="{ background: selectedColorCode }"
            />
          </h3>

          <div v-if="selectedOptions" class="mt-6 flex flex-wrap gap-8">
            <RevTag
              v-for="option in selectedOptions"
              :key="option"
              :label="option"
              variant="secondary"
              variation="outline"
            />
          </div>

          <Price
            class="mt-12"
            :offer="grade"
            :price="price"
            :product="product"
          />
        </div>

        <MobilePlan
          v-if="mobilePlan && shouldDisplayBouyguesAboveTradeIn"
          :plan="mobilePlan"
          :tracking="{
            block_name: 'recap_mobile_plan',
            product_model: product.model,
          }"
        />

        <TradeIn
          v-if="shouldDisplayTradeIn"
          :device="swapOffer?.model"
          :price="swapOffer?.offerPrice"
          :tracking="{
            block_name: 'recap_trade_in',
            product_model: product.model,
          }"
        />

        <MobilePlan
          v-if="
            mobilePlan &&
            experiments['experiment.ppBouyguesPosition'] ===
              'withBouyguesBelowTradein'
          "
          :plan="mobilePlan"
          :tracking="{
            block_name: 'recap_mobile_plan',
            product_model: product.model,
          }"
        />
      </div>

      <div>
        <RevButton
          class="mb-8 mt-12"
          full-width="always"
          variant="primary"
          @click="onAddToCart"
        >
          {{ i18n(translations.resultAddToCart) }}
        </RevButton>

        <p class="text-static-default-low text-caption mb-16 flex items-center">
          <FormattedMessage
            class="truncate"
            :definition="translations.resultSeller"
            tag="span"
          >
            <template #seller>
              <RevLink
                v-if="grade.merchant"
                :to="grade.merchant.link"
                @click="onSellerClick"
              >
                {{ grade.merchant?.company }}
              </RevLink>
            </template>
          </FormattedMessage>

          <Flag
            v-if="shouldDisplaySellerFlag"
            :aria-label="grade.merchant.country"
            class="ml-8 shrink-0"
            :country-code="grade.merchant.countryCode as MarketCountryCode"
          />

          <span v-if="shouldDisplaySellerCountry" class="ml-4 shrink-0">
            ({{ grade.merchant.country }})
          </span>
        </p>

        <ClientOnly>
          <p>
            <PaymentIcons :methods="[...paymentMethods, ...paymentMethods]" />
          </p>
        </ClientOnly>
      </div>

      <div class="body-2-bold mb-56 mt-24 flex flex-col gap-12">
        <ItemShipping :shipping="grade.reassurance?.shipping" />
        <ItemCarriers
          :carriers="product.compatibleCarriers"
          :product-brand="product.trackingDetails.brand"
          :product-category="
            product.trackingDetails.category3MarketplaceCategory_ID
          "
        />
        <ItemReassurance
          :warranty-delay="grade.reassurance?.defaultWarrantyDelay"
        />

        <CopyLevy class="mt-24" :copy-levy="grade.copyLevy" />
        <Vat v-if="isVatIncluded" class="mt-24" />
      </div>

      <slot name="details" />
    </template>
  </StepLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import {
  MarketCountryCode,
  type Price as PriceType,
  productAPI,
} from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { PaymentIcons } from '@backmarket/nuxt-module-payment/methods'
import { usePaymentMethodsWithFallback } from '@backmarket/nuxt-module-payment/methods/composables/usePaymentMethodsWithFallback'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevTag } from '@ds/components/Tag'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/constants'

import { useAddToCart } from '../../../../composables/useAddToCart'
import { useProductTracking } from '../../../../composables/useProductTracking'
import CopyLevy from '../../../CopyLevy/CopyLevy.vue'
import Gallery from '../../../Gallery/Gallery.vue'
import Price from '../../../Price/Price.vue'
import ItemCarriers from '../../../ReassuranceItems/components/ItemCarriers/ItemCarriers.vue'
import ItemReassurance from '../../../ReassuranceItems/components/ItemReassurance/ItemReassurance.vue'
import ItemShipping from '../../../ReassuranceItems/components/ItemShipping/ItemShipping.vue'
import Vat from '../../../Vat/Vat.vue'
import translations from '../../CustomizationFunnel.translations'
import StepLayout from '../StepLayout/StepLayout.vue'

import MobilePlan from './components/MobilePlan/MobilePlan.vue'
import TradeIn from './components/TradeIn/TradeIn.vue'

const COUNTRIES_WITH_SELLER_FLAG = [MarketCountryCode.US, MarketCountryCode.AU]

const props = withDefaults(
  defineProps<{
    grade: productAPI.GetBestOffersResponse[number]
    isFocused?: boolean
    product: productAPI.GetProductResponse
    price: PriceType
    mobilePlan?: MobilePlanOffer
    selectedOptions: Array<string>
    selectedColorCode?: string
    swapStatus?: string
    swapOffer?: Estimation
    tracking: ReturnType<typeof useProductTracking>
  }>(),
  {
    isFocused: true,
    selectedColorCode: undefined,
    swapOffer: undefined,
    swapStatus: '',
    mobilePlan: undefined,
  },
)

const { trackClick } = useTracking()
const marketplace = useMarketplace()
const { addToCart } = useAddToCart()
const experiments = useExperiments()
const i18n = useI18n()
const paymentMethods = usePaymentMethodsWithFallback(
  () => props.grade.paymentMethods,
)

const isVatIncluded = computed(() => {
  return marketplace.features.vatIncluded
})

const shouldDisplaySellerFlag = computed(() => {
  return (
    COUNTRIES_WITH_SELLER_FLAG.includes(marketplace.market.countryCode) &&
    !!props.grade.merchant?.countryCode
  )
})

const shouldDisplaySellerCountry = computed(() => {
  return !!props.grade.merchant?.country && !shouldDisplaySellerFlag.value
})

const images = computed(() => {
  return props.product.images.slice(0, 3)
})

const trackingStepLayout = computed(() => {
  return {
    trackingId: 'recap',
    trackingModel: props.product.model,
  }
})

function onAddToCart() {
  if (props.grade) {
    addToCart({
      listingId: props.grade.id,
      listingPublicId: props.grade.publicId,
      tracking: {
        ...props.tracking.product.value,
        list: 'recap',
      },
      swapStatus: props.swapStatus,
      partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
    })
  }
}

function onSellerClick() {
  trackClick({
    zone: 'recap',
    name: 'seller',
    value:
      props.grade.merchant?.countryCode || props.grade.merchant?.country || '',
  })
}

const shouldDisplayTradeIn = computed(() => {
  return (
    props.swapStatus === SWAP_SUCCESS &&
    !isEmpty(props.swapOffer) &&
    props.swapOffer?.hasOffer
  )
})

const shouldDisplayBouyguesAboveTradeIn = computed(() => {
  return (
    experiments['experiment.ppBouyguesPosition'] ===
      'withBouyguesAboveTradein' ||
    experiments['experiment.ppBouyguesPosition'] === 'noVariation'
  )
})
</script>
