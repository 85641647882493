<template>
  <div v-if="showVatInfoBlock" class="body-2">
    {{ i18n(translations.productVatIncludedInfo) }}
    <RevLink target="_blank" :to="i18n(translations.productVatFaqLink)">
      {{ i18n(translations.productVatFaq) }}
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './Vat.translations'

const i18n = useI18n()

const showVatInfoBlock = computed(() => {
  // In some countries (hello Japan!), we want to display the VAT included info below the price
  // (using the `vatIncluded` boolean in config/app.js), but we don't want to display the * with more details
  // below the seller block. So we check if the translation is set to do so.
  return i18n(translations.productVatIncludedInfo)
})
</script>
