<template>
  <RevAccordionItem>
    <template #title>
      <span class="body-1">
        {{ i18n(question, { brand }) }}
      </span>
    </template>
    <template #body>
      <p
        v-for="answer in answers"
        :key="i18n(answer.paragraph)"
        class="body-2 first:pt-0"
      >
        <span v-if="!answer.link">
          {{ i18n(answer.paragraph, { brand }) }}
        </span>
        <FormattedMessage
          v-if="answer.link && answer.linkText"
          :definition="answer.paragraph"
          :values="{ brand }"
        >
          <template #link>
            <RevLink
              class="body-2-link"
              target="_blank"
              :to="i18n(answer.link)"
            >
              {{ i18n(answer.linkText) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </p>
    </template>
  </RevAccordionItem>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevLink } from '@ds/components/Link'

import type { Answers, Question } from './FaqItem'

defineProps<{
  answers: Answers
  question: Question
  brand: string
}>()

const i18n = useI18n()
</script>
