<template>
  <div class="mb-16 flex flex-col items-center">
    <ClientOnly>
      <RevCarousel
        :alternative-button-label="i18n(translations.alternativeController)"
        :alternative-next="i18n(translations.alternativeNext)"
        :alternative-previous="i18n(translations.alternativePrevious)"
        class="w-full"
        current-index-id="condition-guidance"
        @navigation-click="() => trackCarousel('thumbnail_carousel')"
        @next-button-click="() => trackCarousel('right_arrow')"
        @prev-button-click="() => trackCarousel('left_arrow')"
        @slide-impression="onSlideImpression"
        @swipe="() => trackCarousel('swipe')"
      >
        <div
          v-for="image in images"
          :key="image.src"
          class="relative flex w-full rounded-[20px] md:rounded-[32px]"
        >
          <RevIllustration
            :alt="image.alt ? i18n(image.alt) : ''"
            class="hidden h-auto w-full rounded-[20px] md:block md:rounded-[32px]"
            :height="873"
            :src="image.src + '_md.jpg'"
            :width="1164"
            @load="onImageLoaded"
          />
          <RevIllustration
            :alt="image.alt ? i18n(image.alt) : ''"
            class="h-auto w-full rounded-[20px] md:hidden md:rounded-[32px]"
            :height="600"
            :src="image.src + '_sm.jpg'"
            :width="600"
            @load="onImageLoaded"
          />

          <div
            v-if="image.pills"
            class="absolute inset-x-0 bottom-0 flex flex-col rounded-b-[20px] p-16 md:flex-row md:flex-wrap md:items-center md:rounded-b-[32px] md:pb-24 md:pl-32 md:pt-48"
            :class="$style.bgGradient"
          >
            <p
              class="text-static-light-hi heading-2 mr-8 mt-8"
              :class="{ flex: isPremiumGrade }"
            >
              <img
                v-if="isPremiumGrade"
                alt=""
                height="33"
                :src="toPublicPath('/img/product/funnel/bay_leaf_left.svg')"
                width="20"
              />
              <span>{{ i18n(image.title as I18nDefinition) }}</span>
              <img
                v-if="isPremiumGrade"
                alt=""
                height="33"
                :src="toPublicPath('/img/product/funnel/bay_leaf_right.svg')"
                width="20"
              />
            </p>
            <div class="flex grow flex-row flex-wrap">
              <div
                v-for="pill in image.pills"
                :key="pill.text.id"
                class="bg-static-default-low rounded-full caption mr-8 mt-8 flex w-fit items-center p-4 pr-8 text-center md:body-2"
              >
                <component :is="pill.icon" class="h-16 w-16 shrink-0" />
                <span class="ml-4 text-left">
                  {{ i18n(pill.text as I18nDefinition, { brand }) }}
                </span>
              </div>
              <div
                class="text-static-light-hi caption mt-8 flex shrink-0 grow items-center justify-end gap-4 text-right"
              >
                <span>{{ i18n(translations.disclaimer) }}</span>
                <IconCamera size="16" />
              </div>
            </div>
          </div>
        </div>
      </RevCarousel>
      <div
        v-if="shouldDisplayLoader"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <RevSpinner size="large" />
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { toPublicPath } from '#imports'
import { computed, ref } from 'vue'

import { type Grade } from '@backmarket/http-api'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'
import { RevSpinner } from '@ds/components/Spinner'
import { IconCamera } from '@ds/icons/IconCamera'

import { useUrlParams } from '../../../../composables/useUrlParams'

import { GRADE_TO_GALLERY } from './ConditionGuidance.constants'
import translations from './ConditionGuidance.translations'

const props = withDefaults(
  defineProps<{
    trackingModel?: string
    brand?: string
  }>(),
  {
    trackingModel: '',
    brand: '',
  },
)

const { trackClick } = useTracking()
const { grade } = useUrlParams()
const i18n = useI18n()

const displayedIndex = ref(0)
const loaded = ref<Record<number, Record<number, boolean>>>({
  9: { 0: false, 1: false, 2: false },
  10: { 0: false, 1: false, 2: false },
  11: { 0: false, 1: false, 2: false },
  12: { 0: false, 1: false, 2: false },
})

const images = computed(() => {
  if (!grade.value) {
    return []
  }

  return GRADE_TO_GALLERY[grade.value as Grade['value']]
})

const shouldDisplayLoader = computed(() => {
  if (!grade.value) {
    return false
  }

  return !loaded.value[grade.value][displayedIndex.value]
})

const isPremiumGrade = computed(() => {
  return grade.value === 9
})

function onSlideImpression({ index }: { index: number }) {
  if (index !== displayedIndex.value) {
    displayedIndex.value = index
  }
}

function onImageLoaded() {
  if (grade.value) {
    loaded.value[grade.value][displayedIndex.value] = true
  }
}

function trackCarousel(name: string): void {
  const trackingValues: EventData = {
    name,
    zone: 'pp_step_grade_carousel',
    product_model: props.trackingModel,
  }

  if (grade.value) {
    trackingValues.current_grade = grade.value
  }

  trackClick(trackingValues)
}
</script>

<style module>
@media only screen and (max-width: 767px) {
  .bgGradient {
    background-size: 100% 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
  }
}
@media only screen and (min-width: 768px) {
  .bgGradient {
    background-size: 100% 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    );
  }
}
</style>
