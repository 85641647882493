<template>
  <AdditionalBlock :tracking="tracking">
    <div class="flex items-end justify-between">
      <div>
        <p class="heading-3 flex gap-4">
          <span>{{ plan.label }}</span>
          <RevIllustration
            :alt="plan.partner?.name"
            :height="16"
            src="/img/bouygues/bouyguesLogoSquare.svg"
            :width="16"
          />
        </p>
        <p class="body-2">
          <EnrollmentPeriod
            :enrollment-period="plan.enrollmentPeriod"
            :enrollment-translation="translations.enrollmentPeriod"
          />
        </p>
      </div>
      <div v-if="prices" class="text-right">
        <p class="heading-3">
          {{
            i18n(translations.period1Price, {
              price: i18n.price(prices[0].price),
            })
          }}
        </p>
        <p v-if="prices[0].monthsDuration" class="body-2">
          {{
            i18n(translations.period1Duration, {
              duration: getFormattedDuration(prices[0].monthsDuration ?? 0),
            })
          }}
        </p>
        <p v-if="prices[1]" class="text-static-default-low body-2">
          {{
            i18n(translations.period2Price, {
              price: i18n.price(prices[1].price),
            })
          }}
        </p>
      </div>
    </div>
  </AdditionalBlock>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'
import EnrollmentPeriod from '~/scopes/checkout/components/EnrollmentPeriod/EnrollmentPeriod.vue'

import AdditionalBlock from '../AdditionalBlock/AdditionalBlock.vue'

import translations from './MobilePlan.translations'

const i18n = useI18n()
const { getFormattedDuration } = useFormatMonthsYearsDuration()

const props = defineProps<{
  plan: MobilePlanOffer
  tracking: {
    block_name: string
    product_model: string
  }
}>()

const prices = computed(() => {
  return props.plan.priceOptions.find(
    ({ option }) => option === 'KEEP_PHONE_NUMBER',
  )?.prices
})
</script>
