import { createError, useRoute, useRouter, useRuntimeConfig } from '#imports'
import { type Ref, computed } from 'vue'

import {
  type HttpRequestUrlParameters,
  linkBoosterAPI,
  productAPI,
  productReviewsAPI,
} from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { filterBestOffersABTestedPaymentMethods } from '@backmarket/nuxt-module-payment/methods/helpers/filterABTestedPaymentMethods'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import { MAX_REVIEWS_DISPLAYED_IN_LIST } from '~/scopes/reviews/reviews-display/constants'

import { PREMIUM_DISABLED_COUNTRIES } from '../constants'
import { transformProductResponse } from '../utils/transformProductResponse'

export async function useProductRequests(
  productId: string,
  grade: Ref<number | null>,
  offerType: Ref<number | null>,
  mobilePlan: Ref<string | null>,
) {
  const route = useRoute()
  const router = useRouter()
  const experiments = useExperiments()
  const { countryCode } = useMarketplace().market
  const locale = useI18nLocale()
  const { FF_PRODUCT_SERVICE_PRODUCT = '', KILL_REVIEWS_SERVICE_ON_PP } =
    useRuntimeConfig().public

  const requestParams = { pathParams: { productId } }
  const withPremiumGrade = PREMIUM_DISABLED_COUNTRIES.includes(countryCode)
    ? null
    : 'enabled'

  const {
    getProduct,
    getProductV2,
    getBestOffers,
    getBreadcrumb,
    getProductEcoBlock,
    getPickers,
    getTechnicalSpecifications,
  } = productAPI
  const { getSeoTags } = linkBoosterAPI
  const { getRating, getRatingBadoom, getReviews, getReviewsBadoom } =
    productReviewsAPI

  const pickersQueryParams = computed(() => {
    return removeEmptyValuesInObject({
      grade: grade.value ? String(grade.value) : null,
      special_offer_type: offerType.value ? String(offerType.value) : null,
      top_to_bottom: 'enabled',
      mobile_plans: 'enabled',
      mobile_plan_offer_id: mobilePlan.value ? String(mobilePlan.value) : null,
      premium_grade: withPremiumGrade,
    }) as HttpRequestUrlParameters
  })

  const bestOffersQueryParams = computed(() => {
    return removeEmptyValuesInObject({
      offer_type: offerType.value ? String(offerType.value) : null,
      premium_grade: withPremiumGrade,
    }) as HttpRequestUrlParameters
  })

  const reviewsQueryParams = computed(() => ({
    ...route.query,
    page_size: MAX_REVIEWS_DISPLAYED_IN_LIST,
  }))

  const ratingCall = computed(() =>
    KILL_REVIEWS_SERVICE_ON_PP
      ? useHttpFetch(getRatingBadoom, {
          pathParams: route.params,
          queryParams: {
            withReviewTranslation: true,
          },
          transform: ({ averageRate, reviewsCount: count }) => ({
            averageRate,
            count,
          }),
        })
      : useHttpFetch(getRating, {
          pathParams: route.params,
        }),
  )

  const reviewsCall = computed(() =>
    KILL_REVIEWS_SERVICE_ON_PP
      ? useHttpFetch(getReviewsBadoom, {
          pathParams: route.params,
          queryParams: {
            ...reviewsQueryParams.value,
            withReviewTranslation: true,
          },
          transform: (response) => ({
            ...response,
            results: response.results.map((review) => ({
              handledWithCare: false,
              averageRate: review.rate,
              comment: review.comment,
              countryCode: review.sourceCountry,
              createdAt: review.date,
              id: review.reviewOrderLineId.toString(),
              languageCode: review.sourceLocale,
              customer: {
                firstName: review.firstName,
                lastName: review.lastName,
              },
              details: review.reviews,
              product: {
                ...review.product,
                purchasedAt: review.purchaseDate,
              },
            })),
          }),
        })
      : useHttpFetch(getReviews, {
          pathParams: route.params,
          queryParams: reviewsQueryParams,
        }),
  )

  const productCall = computed(() => {
    const options = {
      pathParams: { productId },
      queryParams: {
        withVisiblePartnership:
          experiments['experiment.ppVisibleByVerizonPartnership'] ===
          'withVisible',
      },
    }

    return FF_PRODUCT_SERVICE_PRODUCT.includes(countryCode)
      ? useHttpFetch(getProductV2, {
          ...options,
          transform: (response: productAPI.GetProductResponseV2) =>
            transformProductResponse(response, countryCode, locale, router),
        })
      : useHttpFetch(getProduct, options)
  })

  const [
    { data: productResponse, error: productError },
    {
      data: bestOffersResponse,
      error: bestOffersError,
      pending: isBestOffersPending,
    },
    { data: breadcrumbResponse },
    { data: technicalSpecificationsResponse },
    { data: tagsResponse },
    { data: ecoBlockResponse },
    { data: pickersResponse, pending: isPickersResponsePending },
    { data: ratesResponse },
    { data: reviewsResponse, pending: isReviewsResponsePending },
  ] = await Promise.all([
    // Required calls
    productCall.value,
    useHttpFetch(getBestOffers, {
      ...requestParams,
      queryParams: bestOffersQueryParams,
      transform: (result) =>
        filterBestOffersABTestedPaymentMethods(experiments, result),
    }),
    // Optional calls
    useHttpFetch(getBreadcrumb, requestParams),
    useHttpFetch(getTechnicalSpecifications, requestParams),
    useHttpFetch(getSeoTags, {
      queryParams: { model: 'product', pk: productId },
    }),
    useHttpFetch(getProductEcoBlock, requestParams),
    useHttpFetch(getPickers, {
      ...requestParams,
      queryParams: pickersQueryParams,
    }),
    ratingCall.value,
    reviewsCall.value,
  ])

  // Those 2 endpoints are the only one required to display a viable product page, allowing the user to add a product to its cart,
  // so that's the only 2 that should trigger an error page (404 or 500)
  if (productError.value || bestOffersError.value) {
    throw createError({
      statusCode:
        productError.value?.statusCode || bestOffersError.value?.statusCode,
      statusMessage: '[PRODUCT] not found',
      // https://nuxt.com/docs/getting-started/error-handling#createerror
      // If you throw an error created with createError:
      // - on server-side, it will trigger a full-screen error page which you can clear with clearError.
      // - on client-side, it will throw a non-fatal error for you to handle. If you need to trigger a full-screen error page, then you can do this by setting fatal: true.
      fatal: true,
    })
  }

  const isOutOfStock = computed(() => {
    if (!bestOffersResponse.value && !isBestOffersPending.value) {
      return true
    }

    return bestOffersResponse.value?.every((offer) => offer.isDisabled) ?? false
  })

  const currentGrade = computed(() => {
    if (!bestOffersResponse.value || isOutOfStock.value) {
      return null
    }

    if (grade.value) {
      return bestOffersResponse.value.find(
        (offer) => offer.backboxGrade.value === grade.value,
      )
    }

    return bestOffersResponse.value.find(
      (offer) => offer.isSelectedByDefault === true,
    )
  })

  return {
    productResponse,
    bestOffersResponse,
    breadcrumbResponse,
    technicalSpecificationsResponse,
    tagsResponse,
    ecoBlockResponse,
    pickersResponse,
    isPickersResponsePending,
    isBestOffersPending,
    ratesResponse,
    reviewsResponse,
    isReviewsResponsePending,
    isOutOfStock,
    currentGrade,
  }
}
