import { useRoute, useRouter } from '#imports'
import { type Ref, onMounted } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useUrlParams } from './useUrlParams'

/**
 * The product pages relies on the URL to know its current state, including the currently selected grade, which lies
 * in the URL hash (i.e.: #l=10).
 *
 * When the page is accessed without any hash, we add the grade based on the default selected grade for the product. This happens quite
 * often from linking in our app or by SEO access.
 */
export const useAddGradeInUrl = (
  currentGrade: Ref<
    productAPI.GetBestOffersResponse[number] | null | undefined
  >,
) => {
  const { grade } = useUrlParams()
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()

  onMounted(() => {
    if (grade.value || !currentGrade.value) {
      return
    }

    logger.info('[PRODUCT] Add missing grade in URL')

    const link = {
      ...route,
      type: 'internal',
      query: {
        ...route.query,
        l: currentGrade.value.backboxGrade.value.toString(),
      },
      hash: route.hash,
    }

    void router.replace(link)
  })
}
