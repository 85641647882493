export default {
  titleStep: {
    id: 'swap_step_title',
    defaultMessage: 'Trade in an old device for {emphasis, html}',
  },
  titleStepEmphasis: {
    id: 'swap_step_title_emphasis',
    defaultMessage: 'cashback on today’s purchase',
  },
  swapSelectPlaceholder: {
    id: 'swap_step_select_placeholder',
    defaultMessage: 'Select brand and model',
  },
  swapSelectNoOptionsMessage: {
    id: 'swap_step_select_no_option',
    defaultMessage: 'No results available',
  },
  clearButtonAriaLabel: {
    id: 'swap_step_select_clear',
    defaultMessage: 'Clear',
  },
  swapSelectLabelOption: {
    id: 'swap_step_select_label_option',
    defaultMessage: '{model} - Up to {price}',
  },
  swapSelectLabelOptionDefault: {
    id: 'swap_step_select_label_option_default',
    defaultMessage: "My device isn't listed here",
  },
  swapSelectExample: {
    id: 'swap_step_select_example',
    defaultMessage: 'e.g. iPhone 11, Samsung Galaxy 10...',
  },
}
