export default {
  title: {
    id: 'reassurance_modal_title',
    defaultMessage: 'Our best in-class warranties',
  },
  titleSatisfaction: {
    id: 'reassurance_modal_title_satisfaction',
    defaultMessage: 'Free 30-day returns',
  },
  titleWarranty: {
    id: 'reassurance_modal_title_warranty',
    defaultMessage: '1-year seller warranty',
  },
}
