<template>
  <FormattedMessage
    class="body-2 flex flex-wrap items-center"
    :definition="translations.monthlyPricePlan"
    :values="{
      duration: monthlyPaymentPlan.numberOfMonths,
      apr: monthlyPaymentPlan.annualPercentageRate,
    }"
  >
    <template #pricePerMonth>
      <span class="flex items-center">
        <RevTag
          :class="[
            {
              'ml-2': props.displayOnTwoLines,
              'mx-2': !props.displayOnTwoLines,
            },
          ]"
          :label="price"
          variant="success"
        />
        <br v-if="props.displayOnTwoLines" />
      </span>
    </template>
  </FormattedMessage>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTag } from '@ds/components/Tag'

import translations from './InstallmentPaymentPrice.translations'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    monthlyPaymentPlan: paymentAPI.MonthlyPaymentPlan
    displayOnTwoLines?: boolean
  }>(),
  {
    displayOnTwoLines: false,
  },
)

const price = computed(() => i18n.price(props.monthlyPaymentPlan.monthlyPrice))
</script>
