export default {
  title: {
    id: 'product_page_new_battery_modal_title',
    defaultMessage: 'New battery',
  },
  section1Title: {
    id: 'product_page_new_battery_modal_section1_title',
    defaultMessage: 'What battery do you need?',
  },
  section1Description: {
    id: 'product_page_new_battery_modal_section1_description',
    defaultMessage:
      'If you prefer 100% battery capacity to the high functioning 85%, a new battery might be for you. It’s perfect if you use your phone for games or work.',
  },
  section1Note: {
    id: 'product_page_new_battery_modal_note',
    defaultMessage:
      '*Battery capacity is the amount of energy a battery can hold, also considered the leading health indicator for a battery.',
  },
  section1Link: {
    id: 'product_page_new_battery_modal_link',
    defaultMessage: 'Learn more about new batteries',
  },
  section2Title: {
    id: 'product_page_new_battery_modal_section2_title',
    defaultMessage: 'Frequently Asked Questions',
  },
  section2Question1: {
    id: 'product_page_new_battery_modal_section2_question1',
    defaultMessage:
      'Do brand new batteries come from the original manufacturer?',
  },
  section2Answer1Paragraph1: {
    id: 'product_page_new_battery_modal_section2_answer1_paragraph1',
    defaultMessage: 'Short answer — nope.',
  },
  section2Answer1Paragraph2: {
    id: 'product_page_new_battery_modal_section2_answer1_paragraph2',
    defaultMessage:
      'You don’t need to worry if the brand-new battery for your iPhone isn’t by Apple. Brand new batteries don’t always come from the original manufacturer because they don’t always perform better.',
  },
  section2Answer1Paragraph3: {
    id: 'product_page_new_battery_modal_section2_answer1_paragraph3',
    defaultMessage:
      'The brand-new batteries on our marketplace are rigorously tested for quality and performance. If there’s ever anything that feels off with your battery-boosted smartphone, {link, html} can help.',
  },
  section2Answer1Paragraph3LinkText: {
    id: 'product_page_new_battery_modal_section2_answer1_paragraph3_link_text',
    defaultMessage: 'Back Market Customer Care',
  },
  section2Answer1Paragraph3Link: {
    id: 'product_page_new_battery_modal_section2_answer1_paragraph3_link',
    defaultMessage: '',
  },
  section2Question2: {
    id: 'product_page_new_battery_modal_section2_question2',
    defaultMessage:
      'Why is the new battery price different with different devices?',
  },
  section2Answer2Paragraph1: {
    id: 'product_page_new_battery_modal_section2_answer2_paragraph1',
    defaultMessage:
      'The price of the new battery fluctuates because the associated cost differs across devices in our marketplace.',
  },
  section2Answer2Paragraph2: {
    id: 'product_page_new_battery_modal_section2_answer2_paragraph2',
    defaultMessage:
      'For example, if a Back Market seller purchased a device that already had a new battery, the associated cost would be less than a device they had to purchase a battery spare part for.',
  },
  section2Answer2Paragraph3: {
    id: 'product_page_new_battery_modal_section2_answer2_paragraph3',
    defaultMessage:
      'Sounds like a conundrum? We make it easier. Back Market handpicks the best mix of price and quality among many different options to help you decide.',
  },
  section2Question3: {
    id: 'product_page_new_battery_modal_section2_question3',
    defaultMessage: 'What are Back Market’s battery standards?',
  },
  section2Answer3Paragraph1: {
    id: 'product_page_new_battery_modal_section2_answer3_paragraph1',
    defaultMessage:
      "Not all refurbished phones come with a new battery because they don't all need one.",
  },
  section2Answer3Paragraph2: {
    id: 'product_page_new_battery_modal_section2_answer3_paragraph2',
    defaultMessage:
      'All smartphone batteries listed on Back Market are checked and tested to guarantee that they have at least 85% battery capacity — which means the overall charge cycle of the device is {link, html}.',
  },
  section2Answer3Paragraph2LinkText: {
    id: 'product_page_new_battery_modal_section2_answer3_paragraph2_link_text',
    defaultMessage: 'considered healthy',
  },
  section2Answer3Paragraph2Link: {
    id: 'product_page_new_battery_modal_section2_answer3_paragraph2_link',
    defaultMessage: '',
  },
  section2Answer3Paragraph3: {
    id: 'product_page_new_battery_modal_section2_answer3_paragraph3',
    defaultMessage:
      'Back Market enforces a guarantee from all sellers that smartphones sold on our marketplace have a minimum of 85% capacity.',
  },
}
