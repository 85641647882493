<template>
  <div class="mb-16">
    <EcoBlockAnchor v-if="showEcoBlock" class="mb-8" />

    <h1 class="heading-1">
      {{ product.rawTitle }}
    </h1>

    <RatingInfo v-if="rating" anchor-id="reviews" :rating="rating" />

    <div class="mt-24 flex items-center gap-12">
      <Price
        v-if="product && currentGrade"
        class="grow"
        :offer="currentGrade"
        :price="price"
        :product="product"
      />

      <RevButton
        class="min-w-[164px] max-w-[256px] grow"
        data-id="product-page-buy-button-desktop"
        data-qa="product-page-buy-button-desktop"
        variant="primary"
        @click="onAddToCart"
      >
        {{ i18n(translations.addToCart) }}
      </RevButton>

      <FavoritesToggle
        class="h-48"
        :grade-id="currentGrade?.backboxGrade.value"
        :offer-type="offerType"
        :price="currentGrade?.price"
        :product-uuid="product.id"
        :tracking-info="tracking.product.value"
        zone="details"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Price as PriceType, productAPI } from '@backmarket/http-api'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import FavoritesToggle from '~/scopes/dashboard/favorites/toggle/FavoritesToggle.vue'
import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import { useUrlParams } from '../../composables/useUrlParams'
import Price from '../Price/Price.vue'

import translations from './TitleDesktop.translations'
import EcoBlockAnchor from './components/EcoBlockAnchor/EcoBlockAnchor.vue'

const props = withDefaults(
  defineProps<{
    currentGrade?: productAPI.GetBestOffersResponse[number] | null
    product: productAPI.GetProductResponse
    price: PriceType
    showEcoBlock?: boolean
    rating?: Rating | null
    tracking: ReturnType<typeof useProductTracking>
    swapStatus?: string
  }>(),
  {
    currentGrade: null,
    showEcoBlock: false,
    rating: null,
    swapStatus: '',
  },
)

const { offerType } = useUrlParams()
const { addToCart } = useAddToCart()
const i18n = useI18n()

function onAddToCart() {
  if (props.currentGrade) {
    addToCart({
      listingId: props.currentGrade.id,
      listingPublicId: props.currentGrade.publicId,
      tracking: {
        ...props.tracking.product.value,
        list: 'product_page',
      },
      swapStatus: props.swapStatus,
      partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
    })
  }
}
</script>
