<template>
  <RevModal
    :name="MODAL_NAMES.REASSURANCE"
    :title="i18n(translations.title)"
    variant="panel"
  >
    <template #body>
      <div class="mb-16">
        <RevIllustration
          alt=""
          class="w-full"
          :height="137"
          src="/img/reassurance/warranty-modal.svg"
          :width="327"
        />
      </div>

      <h2 class="heading-3 mb-16">
        {{ i18n(translations.titleSatisfaction) }}
      </h2>
      <SatisfactionModalContent />

      <RevDivider class="my-16" />

      <h2 class="heading-3 mb-16">
        {{ i18n(translations.titleWarranty) }}
      </h2>
      <WarrantyModalContent />
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'

import { MODAL_NAMES } from '~/scopes/product/constants'
import SatisfactionModalContent from '~/scopes/reassurance/components/SatisfactionModal/SatisfactionModalContent.vue'
import WarrantyModalContent from '~/scopes/reassurance/components/WarrantyModal/WarrantyModalContent.vue'

import translations from './ReassuranceModal.translations'

const i18n = useI18n()
</script>
