<template>
  <div v-if="pending">
    <RevSkeleton class="mb-32" height="32px" shape="rectangle" width="190px" />

    <RevSkeleton
      class="md:hidden"
      height="350px"
      shape="rectangle"
      width="100%"
    />
    <RevSkeleton
      class="hidden md:block"
      height="380px"
      shape="rectangle"
      width="100%"
    />
  </div>

  <div v-else-if="recommendedProduct && totalPrice">
    <BlockTitle :title="title" />
    <RevCard class="hidden py-24 md:block">
      <div class="flex divide-x divide-[var(--rev-t75)]">
        <div class="flex w-2/3 grow items-stretch justify-center">
          <div
            :ref="
              setupViewportTrackingProduct(mainProduct, {
                provider: 'product page',
                list: 'cross sell bundle',
              })
            "
            class="flex w-[200px] flex-col items-center lg:w-[254px]"
          >
            <BundledProduct
              :description="mainProduct.description"
              :grade="mainProduct.grade.name"
              :horizontal="false"
              :image-src="mainProduct.image"
              :price="mainProduct.price"
              :price-new="mainProduct.priceNew"
              :title="mainProduct.title"
              :with-informations="true"
            />
          </div>
          <IconPlus class="mb-[133px] self-center" size="medium" />
          <div
            :ref="
              setupViewportTrackingProduct(recommendedProduct, {
                provider: 'attraqt',
                list: 'cross sell bundle',
              })
            "
            class="flex w-[200px] flex-col items-center lg:w-[254px]"
          >
            <RevButtonBase
              class="h-full"
              :to="`/${recommendedProduct.productPageLink.params.locale}/p/${recommendedProduct.productPageLink.params.slugV2}/${recommendedProduct.productPageLink.params.uuid}`"
            >
              <BundledProduct
                :description="recommendedProduct.description"
                :grade="recommendedProduct.grade.name"
                :horizontal="false"
                :image-src="recommendedProduct.image"
                :price="recommendedProduct.price"
                :price-new="recommendedProduct.priceNew"
                :title="recommendedProduct.title"
                :with-informations="true"
                @click="clickHandler"
              />
            </RevButtonBase>
          </div>
        </div>
        <AddToCartBundleButton
          class="w-1/3 grow p-24"
          :main-product="mainProduct"
          :recommended-product="recommendedProduct"
          :total-price="i18n.price(totalPrice)"
          @add-to-cart="onBundleAddToCart"
        />
      </div>
    </RevCard>

    <RevCard class="flex flex-col gap-24 pt-24 md:hidden">
      <div class="flex items-center justify-center px-16">
        <div
          :ref="
            setupViewportTrackingProduct(mainProduct, {
              provider: 'product page',
              list: 'cross sell bundle',
            })
          "
        >
          <RevIllustration
            :alt="title"
            :height="88"
            :src="mainProduct.image"
            :width="88"
          />
        </div>
        <IconPlus class="shrink-0 px-4" size="medium" />
        <div
          :ref="
            setupViewportTrackingProduct(recommendedProduct, {
              provider: 'attraqt',
              list: 'cross sell bundle',
            })
          "
        >
          <RevButtonBase
            :to="`/${recommendedProduct.productPageLink.params.locale}/p/${recommendedProduct.productPageLink.params.slugV2}/${recommendedProduct.productPageLink.params.uuid}`"
          >
            <RevIllustration
              :alt="title"
              :height="88"
              :src="recommendedProduct.image"
              :width="88"
              @click="clickHandler"
            />
          </RevButtonBase>
        </div>
      </div>
      <OpenBundleModalButton
        :main-product="mainProduct"
        :recommended-product="recommendedProduct"
        :total-price="i18n.price(totalPrice)"
      />
    </RevCard>
  </div>
</template>

<script setup lang="ts">
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import AddToCartBundleButton from '@backmarket/nuxt-layer-recommendation/AddToCartBundleButton.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useImpressionTracking } from '@backmarket/nuxt-layer-recommendation/useProductTracking'
import { useBundleProduct } from '@backmarket/nuxt-layer-recommendation/useRecommendation'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconPlus } from '@ds/icons/IconPlus'

import BundledProduct from './BundledProduct.vue'
import OpenBundleModalButton from './OpenBundleModalButton.vue'

const props = defineProps<{
  title: string
  mainProduct: Product
}>()
const emit = defineEmits<{
  'add-to-cart': [Array<string>]
}>()

function onBundleAddToCart(listings: Array<string>) {
  emit('add-to-cart', listings)
}

const { recommendedProduct, totalPrice, pending, widgetId } = useBundleProduct(
  props.mainProduct,
)

const i18n = useI18n()

const { trackProductClick } = useTracking()
function clickHandler() {
  if (recommendedProduct.value) {
    trackProductClick({
      list: 'cross sell bundle',
      widgetId: widgetId.value,
      product: {
        id: recommendedProduct.value.listingId,
        dealType: 'normal',
        position: 2,
        provider: 'earlybird',
        source: 'recommendations',
        mainListing: props.mainProduct.listingId,
      },
    })
  }
}
const { setupViewportTrackingProduct } = useImpressionTracking()
</script>
