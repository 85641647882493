<template>
  <div v-if="advertisement.isAdvertised">
    <PaypalAdvertisement
      v-if="selectedAdvertisement === 'paypal'"
      :base-price="basePrice"
      :payment-methods="paymentMethods"
      :spot="advertisement.spot"
    />
    <KlarnaAdvertisement
      v-if="selectedAdvertisement === 'klarna'"
      :base-price="basePrice"
      :payment-methods="paymentMethods"
      :spot="advertisement.spot"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Price, paymentAPI } from '@backmarket/http-api'

import { usePaymentMethodsWithFallback } from '../../../methods'
import { usePaymentAdvertisement } from '../../composables/usePaymentAdvertisement'
import { PaymentAdvertisingSpot } from '../../types/PaymentAdvertisingSpot'
import { KLARNA_PAYMENT_METHODS } from '../KlarnaModal/KlarnaModal.config'

import KlarnaAdvertisement from './KlarnaAdvertisement.vue'
import PaypalAdvertisement from './PaypalAdvertisement.vue'

const props = withDefaults(
  defineProps<{
    basePrice: Price
    iconClasses?: string
    spot: PaymentAdvertisingSpot
    paymentMethods?: paymentAPI.PaymentMethod[] | null
    tag?: string
    textSize?: '1' | '2'
  }>(),
  {
    iconClasses: '',
    paymentMethods: null,
    tag: 'div',
    textSize: '2',
  },
)

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)
const includeDisabledMethods = computed(
  () => props.spot === PaymentAdvertisingSpot.CART,
)
const advertisement = usePaymentAdvertisement(
  () => props.spot,
  paymentMethods,
  { includeDisabledMethods },
)

const selectedAdvertisement = computed(() => {
  if (!advertisement.value.isAdvertised) return null

  if (advertisement.value.method.bmCode === 'paypal') return 'paypal'
  if (
    paymentMethods.value.find(({ bmCode }) =>
      KLARNA_PAYMENT_METHODS.includes(bmCode),
    )
  )
    return 'klarna'

  return null
})
</script>
