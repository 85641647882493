<template>
  <div>
    <p class="body-2 -mt-16 mb-16 block md:hidden">
      {{ i18n(translations.description) }}
    </p>
    <RevInfoBlock
      class="mb-32 hidden md:flex"
      :icon="IconEyes"
      :title="
        i18n(
          hasPremium
            ? translations.info_block_with_premium
            : translations.info_block,
        )
      "
      variant="default"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconEyes } from '@ds/icons/IconEyes'

import translations from './ConditionDescription.translations'

withDefaults(
  defineProps<{
    hasPremium: boolean
  }>(),
  {
    hasPremium: false,
  },
)

const i18n = useI18n()
</script>
