<template>
  <RevBreadcrumb
    v-if="breads.length"
    :ariaLabel="i18n(translations.breadcrumb)"
    :breads="breads"
  />
</template>

<script lang="ts" setup>
import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import translations from './Breadcrumb.translations'

defineProps<{
  breads: productAPI.GetBreadcrumbResponse
}>()

const i18n = useI18n()
</script>
