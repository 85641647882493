<template>
  <div
    class="flex h-full items-center gap-8 md:flex-col md:gap-16 md:px-16 md:py-20"
  >
    <div class="shrink-0">
      <RevIllustration
        :alt="title"
        :height="136"
        :src="imageSrc"
        :width="136"
      />
    </div>

    <div
      class="flex flex-col items-start gap-8 md:mt-auto md:items-center md:self-end md:text-center"
    >
      <div class="flex flex-col items-start gap-4 md:items-center">
        <div class="body-1-bold line-clamp-2">
          {{ title }}
        </div>

        <div class="md:text-center">
          <div v-if="description">
            {{ description }}
          </div>
          <div>
            {{
              i18n(translations.gradeDescription, {
                grade: grade,
              })
            }}
          </div>
        </div>
      </div>

      <PriceWithCrossedPrice
        :price="i18n.price(price)"
        :price-new="priceNew && i18n.price(priceNew)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Price } from '@backmarket/http-api'
import PriceWithCrossedPrice from '@backmarket/nuxt-layer-recommendation/PriceWithCrossedPrice.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './BundledProduct.translations'

withDefaults(
  defineProps<{
    title: string
    description?: string
    imageSrc: string
    grade: string
    price: Price
    priceNew?: Price
  }>(),
  {
    description: undefined,
    priceNew: undefined,
  },
)

const i18n = useI18n()
</script>
