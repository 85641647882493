export default {
  linkWrapper: {
    id: 'product_page_copy_levy_link_wrapper',
    defaultMessage: '',
  },
  link: {
    id: 'product_page_copy_levy_link',
    defaultMessage: '',
  },
}
