<template>
  <div>
    <FavoritesToast
      :favorites-toast="favoritesToast"
      @remove-favorite-toast-closed="handleRemoveFavoriteToastClosed"
      @undo="handleUndoRemoveFavorite"
    />

    <RevButtonRounded
      v-if="isRounded"
      :alternative-text="buttonLabel"
      data-qa="my-favorites-toggle"
      :disabled="isDisabled"
      :icon="heartComponentVariant"
      variant="secondary"
      @click="handleClick"
    />

    <RevButtonTiny
      v-if="!isRounded"
      :aria-label="buttonLabel"
      class="h-full"
      data-qa="my-favorites-toggle"
      :disabled="isDisabled"
      variant="secondary"
      @click="handleClick"
    >
      <component :is="heartComponentVariant" class="my-8" />
    </RevButtonTiny>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { computed, ref, watch } from 'vue'

import { type MonetaryAmount, favoriteAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useAuthStore } from '@backmarket/nuxt-module-oauth/useAuthStore'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { IconHeart } from '@ds/icons/IconHeart'
import { IconHeartFilled } from '@ds/icons/IconHeartFilled'
import { storeToRefs } from 'pinia'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'
import { ROUTES } from '~/scopes/auth/route-names'

import {
  FAVORITES_STORAGE_PREFIX,
  FavoritesAction,
  LOG_ERROR_MESSAGE,
} from './FavoritesToggle'
import translations from './FavoritesToggle.translations'
import FavoritesToast from './toast/FavoritesToast.vue'
import { useFavoritesToast } from './toast/useFavoritesToast'

const i18n = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const logger = useLogger()
const { favoritesToast, isRemoveFavoriteToastOpen } = useFavoritesToast()
const { trackAddToMyFavorites, trackClick } = useTracking()

const { authenticated: isAuthenticated } = storeToRefs(authStore)

const props = withDefaults(
  defineProps<{
    favoriteId?: string
    gradeId?: number
    isRounded?: boolean
    offerType: number | null
    price?: MonetaryAmount
    productUuid: string
    zone?: string
    trackingInfo?: Record<string, unknown>
  }>(),
  {
    favoriteId: undefined,
    gradeId: undefined,
    isRounded: false,
    price: undefined,
    offerType: 0,
    zone: 'details',
    trackingInfo: () => ({}),
  },
)

const emit = defineEmits(['removed'])

const isFavorite = ref(Boolean(props.favoriteId))
const localFavoriteId = ref(props.favoriteId)

function resetData() {
  isFavorite.value = Boolean(props.favoriteId)
  localFavoriteId.value = props.favoriteId
  favoritesToast.close()
}

async function getFavoriteFromProduct() {
  if (isFavorite.value || !isAuthenticated.value) {
    return
  }

  try {
    const offerType = props.offerType ?? 0

    const [favorite] = await $httpFetch(favoriteAPI.getFavoritesFromProduct, {
      pathParams: { productUuid: props.productUuid },
      queryParams: { gradeId: props.gradeId, offerType },
    })

    if (!favorite) {
      return
    }

    localFavoriteId.value = favorite.favoriteId
    isFavorite.value = true
  } catch (err) {
    const error = err as Error

    logger.error(LOG_ERROR_MESSAGE.CHECK, { error })
  }
}

const isDisabled = computed(() => {
  return isRemoveFavoriteToastOpen.value || !props.gradeId || !props.price
})

const buttonLabel = computed(() => {
  return isFavorite.value
    ? i18n(translations.favoritesButtonRemoveLabel)
    : i18n(translations.favoritesButtonAddLabel)
})

const uniqueStorageKey = computed(() => {
  const key = `${FAVORITES_STORAGE_PREFIX}_${props.productUuid}_${props.gradeId}_${props.offerType}`
  const keyForRounded = `${key}_rounded`

  return props.isRounded ? keyForRounded : key
})

const heartComponentVariant = computed(() => {
  return isFavorite.value ? IconHeartFilled : IconHeart
})

function storePendingAction(pendingAction: FavoritesAction) {
  sessionStorage?.setItem(uniqueStorageKey.value, pendingAction)
}

function requestAuthentication() {
  const next = route.fullPath

  router.push({
    name: ROUTES.AUTH.LOGIN,

    query: {
      next,

      // needed for the info toast on Auth page
      info: 'my-favorites',
      bm_journey: 'favorites',
    },
  })
}

async function addToFavorites() {
  if (isFavorite.value) {
    favoritesToast.openAlreadySavedFavToast()

    return
  }

  try {
    const { productUuid, gradeId, price } = props
    const offerType = props.offerType ?? 0

    const { favoriteId } = await $httpFetch(favoriteAPI.createFavoriteForUser, {
      body: {
        productUuid,
        gradeId,
        price,
        offerType,
      },
    })

    favoritesToast.openSavedFavToast()

    localFavoriteId.value = favoriteId

    isFavorite.value = true
  } catch (err) {
    const error = err as Error

    logger.error(LOG_ERROR_MESSAGE.ADD, { error })
  }
}

async function removeFromFavorites() {
  try {
    const favoriteId = localFavoriteId.value

    await $httpFetch(favoriteAPI.deleteUserFavorite, {
      pathParams: { favoriteId },
    })

    localFavoriteId.value = ''
    isFavorite.value = false

    emit('removed')
  } catch (err) {
    const error = err as Error

    logger.error(LOG_ERROR_MESSAGE.REMOVE, { error })
  }
}

function clearPendingAction() {
  sessionStorage?.removeItem(uniqueStorageKey.value)
}

function executePendingAction() {
  if (!process.client || !isAuthenticated.value) {
    return
  }

  const action = sessionStorage?.getItem(uniqueStorageKey.value)
  clearPendingAction()

  if (!action) {
    return
  }

  try {
    if (action === FavoritesAction.Add) {
      addToFavorites()
    } else if (action === FavoritesAction.Remove) {
      removeFromFavorites()
    }
  } catch (err) {
    const error = err as Error

    logger.error(LOG_ERROR_MESSAGE.PENDING, { error })
  }
}

async function initialAction() {
  if (!isAuthenticated.value) {
    return
  }

  await getFavoriteFromProduct()
  executePendingAction()
}

function trackAddClick() {
  trackAddToMyFavorites(props.trackingInfo as EventData)
}

const pageType = computed(() => {
  const routeName = String(route.name)

  return TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName]
})

function trackRemoveClick() {
  const name = 'remove_from_my_favorites'

  trackClick({ name, pageType: pageType.value, zone: props.zone })
}

function handleClick() {
  const doTrackClick = isFavorite.value ? trackRemoveClick : trackAddClick
  doTrackClick()

  if (!isAuthenticated.value) {
    storePendingAction(FavoritesAction.Add)

    requestAuthentication()

    return
  }

  if (isFavorite.value) {
    storePendingAction(FavoritesAction.Remove)

    favoritesToast.openRemoveFavToast()

    // optimistic UI: displays an empty heart right away,
    // even though the favorite has not been removed from the database yet
    isFavorite.value = false

    return
  }

  addToFavorites()
}

function handleRemoveFavoriteToastClosed() {
  executePendingAction()
}

function handleUndoRemoveFavorite() {
  isFavorite.value = true

  clearPendingAction()
}

function optionUpdated() {
  resetData()
  initialAction()
}

watch(isAuthenticated, () => {
  optionUpdated()
})

watch(
  () => props.productUuid,
  () => optionUpdated(),
)

watch(
  () => route.hash,
  () => optionUpdated(),
)

initialAction()
</script>
