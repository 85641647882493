<template>
  <ul class="flex w-full list-none p-2">
    <li
      v-for="(item, index) in count"
      :key="index"
      class="rounded-xs relative mr-8 h-[2px] flex-1 last:mr-0"
    >
      <span
        v-if="index >= currentIndex"
        class="bg-action-default-mid absolute inset-0"
      />
      <span
        v-if="index === currentIndex"
        :key="index"
        class="bg-action-default-hi absolute inset-0"
        :class="$style.filling"
        :style="{ 'animation-duration': `${duration}s` }"
      />
      <span
        v-if="index < currentIndex"
        class="bg-action-default-hi absolute inset-0"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  items: Array<{ duration: number }>
  currentIndex: number
}>()

const count = computed(() => {
  return props.items.length
})

const duration = computed(() => {
  return props.items[props.currentIndex].duration
})
</script>

<style module>
.filling {
  animation: fill 2s linear;
}
@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
