<template>
  <!-- id used for custom klarna integration styling below -->
  <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
  <klarna-placement
    id="klarna-placement"
    data-key="credit-promotion-badge"
    :data-locale="locale"
    :data-purchase-amount="purchaseAmount"
  ></klarna-placement>
</template>

<script setup lang="ts">
import { type Ref, computed, onMounted, ref } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { noop } from '@backmarket/utils/function/noop'
import { priceToMinorUnits } from '@backmarket/utils/math/priceToMinorUnits'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { usePaymentMethodsWithFallback } from '../../../methods'
import { type PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'
import { KLARNA_PAYMENT_METHODS } from '../KlarnaModal/KlarnaModal.config'
import { useKlarnaLibrary } from '../KlarnaModal/useKlarnaLibrary'

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'KlarnaAdvertisement',
  paymentMethods: null,
})

const { market } = useMarketplace()
const locale = computed(() => toRfc4646Locale(market.defaultLocale))
const klarnaPlacement: Ref<HTMLElement | null> = ref(null)
const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)
const purchaseAmount = computed(() => priceToMinorUnits(props.basePrice))

const klarnaPaymentMethod = computed(() => {
  const paymentMethod = paymentMethods.value.find(({ bmCode }) =>
    KLARNA_PAYMENT_METHODS.includes(bmCode),
  )

  if (!paymentMethod) {
    throw new Error('Could not find any Klarna payment method')
  }

  return paymentMethod
})

const { load } = useKlarnaLibrary(
  klarnaPaymentMethod.value,
  noop,
  // Lazy-load the library, to improve performance (especially important on the product page).
  { immediate: false },
)

const displayAdvertisement = async () => {
  await load()
}

onMounted(() => {
  displayAdvertisement()
})
</script>

<style>
/* Targeting parts of the custom element */
#klarna-placement::part(osm-container) {
  padding: 0;
  border: none;
}

#klarna-placement::part(osm-message) {
  font-family: BMDupletTXT, HelveticaTXT, sans-serif;
  font-size: small;
}

#klarna-placement::part(osm-legal) {
  font-family: BMDupletTXT, HelveticaTXT, sans-serif;
  font-size: xx-small;
  display: none;
}
#klarna-placement::part(osm-cta) {
  font-family: BMDupletTXT, HelveticaTXT, sans-serif;
  font-size: small;
}

#klarna-placement::part(osm-badge) {
  width: 50px;
}
</style>
