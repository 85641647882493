<template>
  <div class="mb-32">
    <p class="body-1 mb-8 block">
      {{ i18n(translations.title) }}
    </p>

    <div class="flex flex-wrap gap-8">
      <Chip v-for="item in items" :key="item.label" :label="item.label">
        <template #prefix>
          <component :is="item.icon" aria-hidden="true" />
        </template>
      </Chip>

      <VisibleByVerizonChip
        v-if="hasVisibleByVerizon(partnerPromoCodes)"
        tracking-zone="visible_info_accessories_section"
      />
    </div>

    <RevLink
      v-if="comesWith.displayAccessoryExclusionsModal"
      class="block text-left"
      @click="handleModalOpen"
    >
      {{ i18n(translations.modalTrigger) }}
    </RevLink>

    <ExcludedAccessoriesModal />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { IconChargingWire } from '@ds/icons/IconChargingWire'
import { IconComputerMouse } from '@ds/icons/IconComputerMouse'
import { IconController } from '@ds/icons/IconController'
import { IconHdmi } from '@ds/icons/IconHdmi'
import { IconHeart } from '@ds/icons/IconHeart'
import { IconKeyboard } from '@ds/icons/IconKeyboard'
import { IconPlug } from '@ds/icons/IconPlug'
import { IconPowerAdapterApple } from '@ds/icons/IconPowerAdapterApple'

import Chip from '~/scopes/product/components/Chip/Chip.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import { hasVisibleByVerizon } from '../../utils/hasVisibleByVerizon'
import VisibleByVerizonChip from '../VisibleByVerizonChip/VisibleByVerizonChip.vue'

import translations from './Accessories.translations'
import ExcludedAccessoriesModal from './components/ExcludedAccessoriesModal/ExcludedAccessoriesModal.vue'

const ICONS: Record<string, unknown> = {
  IconChargingWire,
  IconComputerMouse,
  IconController,
  IconHdmi,
  IconHeart,
  IconKeyboard,
  IconPlug,
  IconPowerAdapterApple,
}

const props = defineProps<{
  comesWith: productAPI.GetTechnicalSpecificationsResponse['comesWith']
  partnerPromoCodes: productAPI.GetProductResponse['includedServiceOffers']['partnerPromoCodes']
}>()

const i18n = useI18n()

const items = computed(() => {
  return (props.comesWith.items ?? []).map((accessory) => ({
    icon: ICONS[accessory.icon],
    label: accessory.text,
  }))
})

function handleModalOpen() {
  openModal(MODAL_NAMES.EXCLUDED_ACCESSORIES)
}
</script>
