<template>
  <div>
    <h3 class="heading-3">
      {{ i18n(translations.SeoSellerContentTitle, { model: title }) }}
    </h3>
    <div
      :class="[
        'text-static-default-low mt-12',
        {
          'max-h-[12rem] overflow-hidden text-ellipsis md:max-h-[7.5rem]':
            hideLongContent,
        },
      ]"
    >
      <div class="body-1" data-selector="pp-text">
        <!-- The product description is sent by the API as HTML. -->
        <!-- eslint-disable vue/no-v-html -->
        <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
        <div class="mb-24" v-html="description" />
        <!-- eslint-enable -->
        <div>
          <h2 class="body-1-bold mb-24">
            {{ technicalSpecifications.title }}
          </h2>
          <ul class="list-none">
            <li
              v-for="(
                { display, values }, index
              ) in technicalSpecificationsArray"
              :key="index"
            >
              <span class="body-1-bold"> {{ display }} : </span>

              <span v-for="value in values" :key="value.label" class="body-1">
                {{ value.label }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <RevLink v-if="shouldDisplayReadMore" class="mt-12" @click="toggleEllipsis">
      <span class="body-1-bold">
        <span v-if="hideLongContent">
          {{ i18n(translations.showMore) }}
        </span>

        <span v-if="!hideLongContent">
          {{ i18n(translations.showLess) }}
        </span>
      </span>
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import { productAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLink } from '@ds/components/Link'

import translations from './SeoContent.translations'

const MAX_DISPLAYED_LENGTH = 420

const props = defineProps<{
  title: string
  description: string
  technicalSpecifications: productAPI.GetTechnicalSpecificationsResponse
}>()

const i18n = useI18n()
const hideLongContent = ref(false)

function stringifiedSpecificationsValues(
  specificationValues: ReadonlyArray<string>,
) {
  if (!isEmpty(specificationValues)) {
    return Object.values(specificationValues).join(', ')
  }

  return ''
}

const technicalSpecificationsArray = computed(() => {
  const { specifications = [] } = props.technicalSpecifications

  return specifications.filter(({ values }) => !isEmpty(values))
})

const technicalSpecificationsStringifiedLength = computed(() => {
  return Object.values(technicalSpecificationsArray.value).reduce(
    (str, { display, values = [] }) => {
      return `${str} ${display}: ${stringifiedSpecificationsValues(
        values.map(({ label }) => label),
      )}`
    },
    '',
  ).length
})

// Since `description` can contain html, we need to
// count characters without the tags
const parsedDescriptionLength = computed(() => {
  const regex = /(<([^>]+)>)/gi
  const parsedDescription = props.description.replace(regex, '')

  return parsedDescription.length
})

const shouldDisplayReadMore = computed(() => {
  return (
    technicalSpecificationsStringifiedLength.value +
      parsedDescriptionLength.value >
    MAX_DISPLAYED_LENGTH
  )
})

function toggleEllipsis() {
  hideLongContent.value = !hideLongContent.value
}

onMounted(() => {
  if (shouldDisplayReadMore.value) {
    hideLongContent.value = true
  }
})
</script>
